/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useGetQueueDataByUserQuery, useProjectQuery, useGetDeployQueueDataQuery, useGetDeploySSHMutation, useGetUserComposerQuery, useDeleteQueueDataMutation, useAddWpPluginMutation, usePluginOperationMutation, useAddSSHDetailMutation } from '../features/api';
import { useAppSelector } from '../features/hooks';
import { useAddQueueMutation } from '../features/api';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { Steps } from 'intro.js-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faExclamationTriangle, faQuestion } from '@fortawesome/free-solid-svg-icons';
import Loader from './Loader';
// import { Modal } from 'react-bootstrap';
import backarrow from '../img/acc-arw.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import CommonModal from './CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeployData } from '../features/getDeployData';
import { useDeleteQueryString, useManageQueryString, useUpdateQueryString } from '../utils/queryStringFunc';
import { updateTrigger } from '../features/introArray';
import { getColor } from '../utils/differentFunctions';
import CommonAlert from './CommonAlert';
import { getSSHDetails } from '../features/getSSHDetails';
const envList = ['Production', 'Staging', 'QA'];

const ReviewQueue:React.FC = () => {
  const [syncQueue, {}] = useAddQueueMutation();
  const {t} = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [queue, setQueue] = useState([]);
  const [deployTypeString, setDeployTypeString] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deployType, setDeployType] = useState('option1');
  const [deleteQueueData,] = useDeleteQueueDataMutation()
  const getWpQueue =
    localStorage.getItem('updated-queue') !== null ? JSON.parse(localStorage.getItem('updated-queue')!) : [];
  const getComposerQueue =
    localStorage.getItem('composer-queue') !== null ? JSON.parse(localStorage.getItem('composer-queue')!) : [];
  const queueComposerUpdater: any =
    localStorage.getItem('composer-plugin-add') !== null
      ? JSON.parse(localStorage.getItem('composer-plugin-add')!)
      : [];
  const pathname = window.location.pathname;
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const [storeQueue, setStoreQueue] = useState<Array<any>>([]);
  const queryString = new URLSearchParams(window.location.search);
  const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
  // const queryString = new URLSearchParams(window.location.search)
  const location = useLocation();
  const deleteQueryString = useDeleteQueryString();
  const updateQueryString = useUpdateQueryString();
  const manageQueryString = useManageQueryString();

  useEffect(() => {

    console.log('hello',location.search)
    if(location.search.length > 0){
      if(queryString.get("intro")){
        setStepsEnabled(true)
      }
    }

  },[location.search])
  let initialSteps = 0;
  let ReviewQueueSteps = [
    {
      title: t('intro.deploy.first.title'),
      position: 'right',
      intro: t('intro.deploy.first.desc'),
    },
    {
      title: t('intro.deploy.two.title'),
      element: '.review-queue-first',
      position: 'left',
      intro: t('intro.deploy.two.desc'),
    },
  ];
  useEffect(() => {
    dispatch(fetchDeployData())
    dispatch(getSSHDetails())
  },[dispatch])
  // const { data:projectsList } = useProjectQuery({
  //     data: { "with-plugins": true },
  //   });
  // const { data: queueData, isLoading: projectLoad } = useGetDeployQueueDataQuery({});
  const { data: queueData, isLoading: projectLoad } = useSelector((state:any) => state.deployData);
  console.log("isLoading",queueData)
  const [show, setShow] = useState<boolean>(false);
  const [checkboxSsh, setCheckboxSsh] = useState<boolean>(false);
  const [sshData, setSshData] = useState<any>({
    url: '',
    username: '',
    password: '',
    premium_username: '',
    premium_password: '',
    path_to_wp_installation: '',
    path_to_wp_content_folder: '',
    private_key: '',
    paraphrase: '',
    subtype: '',
    meta: '',
    text:''
  });
  const [sshError, setSshError] = useState<any>({});
  const [isSshFileAdded, setIsSshFileAdded] = useState<string>('');
  const [fileSsh, setFileSsh] = useState<string>('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [specificData, setSpecificData] = useState<any>({})
  const [deploySSH,] = useGetDeploySSHMutation()
  const {data:premiumData} =useGetUserComposerQuery({})
  const [addWpPlugin,] = useAddWpPluginMutation()
  const [openConcentAlert,setOpenConcentAlert] = useState<boolean>(false)
  const handleModal=()=>{
    setIsModalOpen(true)
  }
  const [pluginOperation,] = usePluginOperationMutation()
  const [AddSSHDetail,] = useAddSSHDetailMutation()
  useEffect(() => {
    if (queueData !== null) {
      if (queueData.length > 0) {
        setStoreQueue(queueData);
      }
    }
  }, [queueData, queryString.get('project'), queryString.get('environment')]);
  const introArray = useAppSelector((state) => state.introArray.arrayData)
  const {data:getsshdata} = useAppSelector((state) => state.getSSHData)
  const [sshData1,setSshData1] = useState<Array<any>>([])
  console.log("introArray",introArray,getsshdata)

  useEffect(() => {
    console.log("introArray",introArray)
    if(introArray.length > 0){
      const getspecificdata = introArray.find((ik) => ik.page === "deploy")
      if(getspecificdata !== undefined){
        if(getspecificdata.trigger === true){
          setStepsEnabled(true)
        }
      }
    }

  },[introArray])

  useEffect(() => {
    if(getsshdata !== null){
      setSshData1(getsshdata)
    }
  },[getsshdata])
  // const userPlugins = useAppSelector((state) => state.userplugins.value);
  // useEffect(() => {
  //     if(projectsList){
  //     // const filterdPro=projectsList.projects?.filter((project:any) => project.composer.some((e:any)=>e.composer_queue!==null))
  //     // setQueue(filterdPro)
  // }
  // }, [projectsList])
  // const removePlugin=(queueCompo:string,remove:string,projectId?:Number,envId?:Number)=>{
  //     let remainigdata=JSON.parse(queueCompo)
  //     delete remainigdata[remove]
  //     if(remainigdata){
  //         let data={
  //             project:projectId,
  //             env:envId,
  //             composer:remainigdata
  //           }
  //           syncQueue(data)
  //     }
  // }

  // const getPlugins=(queueCompo:string,projectId:Number,envId:Number)=>{
  //     let ROWS;
  //     if(queueCompo){
  //          ROWS = Object.entries(JSON.parse(queueCompo)).map((pluginData:any)=>{
  //             let plugin=userPlugins.allPlugins.find((i)=>i.slug===pluginData[0].split('/')[1])
  //             return (<tr>
  //                 <td>{plugin?.name}</td>
  //                 <td>plugin</td>
  //                 <td>{pluginData[1]}</td>
  //                 <td><div dangerouslySetInnerHTML={{ __html: `this is a description for ${plugin?.name}` }} /></td>
  //                 <td><button className="yellow_btn" onClick={e=>removePlugin(queueCompo,pluginData[0],projectId,envId)}>Remove</button></td>
  //             </tr>)
  //         })
  //     }
  //     return ROWS
  // }

  const redirectToDeploy = async (item: any, updaterType: string, environment: any): Promise<void> => {
    console.log("environment",environment,item)
    if (deployTypeString.length > 0) {
      const params = { project_id: item.project.project_id, type: updaterType, environment: environment.id };
      if (updaterType === 'add_plugin_queue_composer') {
        navigate({ pathname: `/deploy/${deployTypeString}`, search: `?${createSearchParams(params)}` });
      } else if (updaterType === 'composer_queue') {
        if(deployTypeString === 'deploy-using-ssh'){
          setShow(true)
          setSpecificData(item)
        } else {
          navigate({ pathname: `/deploy/${deployTypeString}`, search: `?${createSearchParams(params)}` });
        }
      } else if (updaterType === "wp_connector_queue") {
        console.log('do nothing');
        if(deployTypeString === "deploy-using-wp-connector-plugin"){
          try{
            const meta = JSON.parse(item.enviornments.meta)
            const encodedCredentials = Buffer.from(`${meta?.username}:${atob(meta?.app_password)}`).toString('base64');
            const headers = {
                Authorization: `Basic ${encodedCredentials}`,
              };
            const plugs = await axios.get(`${item.enviornments.siteurl}/wp-json/wp/v2/plugins`,{ headers })
            console.log('plugs',plugs)

            if(plugs){
              const updatedData1 = plugs.data.filter((item1:any) => {return item.plugins.some((i:any) => {return item1.plugin.split('/')[0] === i.slug})});
              const updatedData2 = item.plugins.filter((item1:any) => {return !plugs.data.some((i:any) => {return i.plugin.split('/')[0] === item1.slug})});
              console.log("updatedData1",updatedData1,updatedData2)
              const updateData3 = updatedData1.map((i:any) => {
                const bac = item.plugins.find((ikk:any) => {return i.plugin.split('/')[0] === ikk.slug})
                return {
                  ...i,
                  wpData:bac !== undefined ?bac :{}
                }
              })
              const meta1= JSON.parse(item.enviornments.meta!)
              const sendData1 = {
                env_id:item.enviornments.id,
                project_id:item.project.project_id,
                siteurl:item.enviornments.siteurl,
                env_type:meta1.type,
                env_user:meta1.username,
                env_password:atob(meta1.app_password),
                wp_obj:{
                  type:"wordpress_plugins",
                  data:updateData3
                },
                data_obj:{
                  type:"wp_plugin",
                  data:updatedData2
                }
              }
              console.log("sendData1",sendData1)
              pluginOperation(sendData1).then((res11:any) => {
                toast.info(`Your environment plugin is ongoing using Wp REST-API method when its completed we inform you`)
              }).catch((err11) => {
                toast.error('Something went wrong')
              })

            }

          } catch(err){

          }
        } else if(deployTypeString === 'deploy-using-ssh'){
          setShow(true)
          setSpecificData(item)
        }

      }
      //   if(typeof window !== "undefined"){
      //     window.alert("Under Development")
      // }
    } else {
      if (typeof window !== 'undefined') {
        window.alert('please select atleast one method');
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'review-queue-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'review-queue-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'review-queue-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        // manageQueryString(homeScreen.status)
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'review-queue-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'review-queue-screen';
      });
      if (index >= 0) {
        array[index].status = 'false';
        // manageQueryString(array[index].status)
        dispatch(updateTrigger({page: 'deploy', trigger: false}))
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };

  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'review-queue-screen';
      });
      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };
  const redirectToProject = (project: any): void => {
    if (window.confirm('You need to configure your environment before deploy process')) {
      const params = {
        environment: project.enviornments.id,
      };
      navigate({
        pathname: `/projects/${project.project.project_id}`,
        search: `?${createSearchParams(params)}`,
      });
    } else {
      console.log('do nothing');
    }
  };

  function isValidJSONString(str: string, ik: any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      console.log('e', e, ik);
      // toast.error(`there is json parse error in ${ik.meta.name} in ${item.name}`)
      return false;
    }
  }

const removeStaticPortion = (url:string):string => {
    const patternsToRemove = [
        "https://fusepress.fra1.digitaloceanspaces.com/",
        "fra1.digitaloceanspaces.com/fusepress/"
    ];

    let cleanedUrl = url;
    patternsToRemove.forEach(pattern => {
        cleanedUrl = cleanedUrl.replace(pattern, '');
    });

    return cleanedUrl;
};

const downLoadScriptFile = (anotherArray:any):void => {
  const scriptContent = anotherArray.join('\n');
  const blob = new Blob([scriptContent], { type: 'text/plain' });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a temporary anchor element
  const a = document.createElement('a');
  a.href = url;
  a.download = 'script.sh'; // The name of the file to be downloaded

  // Append the anchor to the body
  document.body.appendChild(a);

  // Trigger a click on the anchor to start the download
  a.click();

  // Remove the anchor from the document
  document.body.removeChild(a);

  // Revoke the object URL to free up memory
  URL.revokeObjectURL(url);
}

 const onProjectConfigStep = async(event:any,type:string):Promise<void> => {
  event.preventDefault();
  if(validateSsh()){
    if(type === "ssh"){
    handleClose()
    setOpenConcentAlert(true)
  }
  }

 }

 const saveAndPerformSshAction = async(action:boolean,type:string):Promise<void> => {
  if(action === true){
    const data11 = {
      url:sshData.url,
      user:sshData.username,
      private_key:sshData.private_key,
      password:sshData.password,
      subtype:checkboxSsh === true ? "private_key":"password",
      key_paraphase:sshData.paraphase,
      wp_path:sshData.path_to_wp_installation,
      premium_username:sshData.premium_username,
      premium_password:sshData.premium_password,
      flag:"fdsg"
    }
    AddSSHDetail(data11).then((res11:any) => {
      console.log("res11",res11)
      if(Object.prototype.hasOwnProperty.call(res11.data,"error")){
        toast.error('Something went wrong while storing data')
      } else {
        toast.success(res11.data.message)
        dispatch(getSSHDetails())
      }
      console.log(res11)
    }).catch((err) => {
      toast.error('something went wrong while storing ssh data')
    })
  }
  if(type === 'ssh'){
    console.log("specificData",specificData)
    const meta1 = JSON.parse(specificData.enviornments.meta)
    if(meta1.type === "wp"){
      const specificData1 = specificData.plugins.map((ik:any) => {
        if(ik.premiumPackage === true){
          const fusepressPath = removeStaticPortion(ik.fusepress_path)

          const getName = fusepressPath.split('/')[fusepressPath.split('/').length -1]

          const packageBase64 = Buffer.from(fusepressPath).toString('base64')
          const url = `${process.env.REACT_APP_AUTH_NODE_URL}/api/download/download-package?exp_key=${packageBase64}&bucket=fusepress&user_id=${userdetails.id}&filename=${getName}`

          const command =  ik.action !== null? ik.action === "add" ? `curl -u ${sshData.premium_username}:${sshData.premium_password} -o ${sshData.path_to_wp_installation}/${getName} '${url}' && wp plugin install ${sshData.path_to_wp_installation}/${getName} --activate --path=${sshData.path_to_wp_installation}`:ik.action==="delete"?`wp plugin deactivate ${ik.slug} && wp plugin delete ${ik.slug} --path=${sshData.path_to_wp_installation}`:ik.action==="inactive"?`wp plugin deactivate ${ik.slug}`:ik.action==="active"?`wp plugin activate ${ik.slug}`:ik.action==="update"?`wp plugin deactivate ${ik.slug} && wp plugin delete ${ik.slug} --path=${sshData.path_to_wp_installation} && curl -u ${sshData.premium_username}:${sshData.premium_password} -o ${sshData.path_to_wp_installation}/${getName} '${url}' && wp plugin install ${sshData.path_to_wp_installation}/${getName} --activate --path=${sshData.path_to_wp_installation}`:null:null
          return {...ik,command:command}
        } else {
          const command1 = ik.action !== null? ik.action === "add" ?`wp plugin install ${ik.slug} --version=${ik.plugin_version} --path=${sshData.path_to_wp_installation} --activate`:ik.action==="delete"?`wp plugin deactivate ${ik.slug} && wp plugin delete ${ik.slug} --path=${sshData.path_to_wp_installation}`:ik.action==="inactive"?`wp plugin deactivate ${ik.slug}`:ik.action==="active"?`wp plugin activate ${ik.slug}`:ik.action==="update"?`wp plugin deactivate ${ik.slug} && wp plugin delete ${ik.slug} --path=${sshData.path_to_wp_installation} && wp plugin install ${ik.slug} --version=${ik.plugin_version} --path=${sshData.path_to_wp_installation} --activate`:null:null
          return {...ik,command:command1}
        }
      })
      const specificData2 ={
        enviornments:specificData.enviornments,
        plugins:specificData1,
        project:specificData.project,
      }
      const data1 = {
        host:sshData.url,
        username:sshData.username,
        private_key:sshData.private_key,
        password:sshData.password,
        isPrivateKey:checkboxSsh,
        paraphase:sshData.paraphase,
        data:specificData2,
        wp_path:sshData.path_to_wp_installation,
        premium_username:sshData.premium_username,
        premium_password:sshData.premium_password
      }

      const commands = specificData1.map((ik:any) => {
       return ik.command
      })
      const bin1 = `#!/bin/bash`
      const bin2 = `#chmod +x script1.sh If you get the error of "Permission denied" in script1.sh run this command to resolve the error.`
  const bin3 = `#./script1.sh After running this command, you should be able to execute the script:`


      const anotherArray = [bin1,bin2,bin3, ...commands]
      downLoadScriptFile(anotherArray)

      toast.info('your deploy process is on going once completed you will be notified')

      setOpenConcentAlert(false)
      try{
        await axios({
          url: `${process.env.REACT_APP_AUTH_NODE_URL}/api/wp-cli/wp-access`,
          method: 'POST',
          data: data1,
          headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        }).then((res1) => {
        })
      } catch(err1){
        toast.error(`something went wrong`)
      }


    } else {
      const specificData1 = specificData.plugins.map((ik:any) => {
        if(ik.premiumPackage === true){
          const fusepressPath = removeStaticPortion(ik.fusepress_path)

          const getName = fusepressPath.split('/')[fusepressPath.split('/').length -1]

          const packageBase64 = Buffer.from(fusepressPath).toString('base64')
          const url = `${process.env.REACT_APP_AUTH_NODE_URL}/api/download/download-package?exp_key=${packageBase64}&bucket=fusepress&user_id=${userdetails.id}&filename=${getName}`

          const command =  `curl -u ${sshData.premium_username}:${sshData.premium_password} -o ${sshData.path_to_wp_installation}/${getName} '${url}' && wp plugin install ${sshData.path_to_wp_installation}/${getName} --activate --path=${sshData.path_to_wp_installation}`
          return {...ik,command:command}
        } else {
          const command1 = `wp plugin install ${ik.slug} --version=${ik.plugin_version} --path=${sshData.path_to_wp_installation} --activate`
          return {...ik,command:command1}
        }
      })
      const specificData2 ={
        enviornments:specificData.enviornments,
        plugins:specificData1,
        project:specificData.project,
      }
      const data1 = {
        host:sshData.url,
        username:sshData.username,
        private_key:sshData.private_key,
        password:sshData.password,
        isPrivateKey:checkboxSsh,
        paraphase:sshData.paraphase,
        data:specificData2,
        wp_path:sshData.path_to_wp_installation,
        premium_username:sshData.premium_username,
        premium_password:sshData.premium_password
      }

      const commands = specificData1.map((ik:any) => {
       return ik.command
      })
      const bin1 = `#!/bin/bash`
      const bin2 = `#chmod +x script1.sh If you get the error of "Permission denied" in script1.sh run this command to resolve the error.`
  const bin3 = `#./script1.sh After running this command, you should be able to execute the script:`


      const anotherArray = [bin1,bin2,bin3, ...commands]
      downLoadScriptFile(anotherArray)

      toast.info('your deploy process is on going once completed you will be notified')
      setOpenConcentAlert(false)
      try{
         await axios({
          url: `${process.env.REACT_APP_AUTH_NODE_URL}/api/wp-cli/wp-access`,
          method: 'POST',
          data: data1,
          headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        }).then((res1) => {
        })
      } catch(err1){
        toast.error(`something went wrong`)
      }

    }

  // }

 }
 }

 const validateSsh = () => {
  let newError:any = {};

  const containsRequiredCharsForPremiumPassword = (str:any) => {
    return str &&  str.length >= 16 &&
          /[A-Za-z]/.test(str) &&
          /\d/.test(str);
  };

  const containsRequiredCharsForPassword = (str:any) => {
    return str &&  str.length > 4 &&
          /[A-Za-z]/.test(str)
  };

  const isValidPremiumUsername = (str: any) => {
    return str && str.length >= 8 &&
          /^[a-z0-9]+$/.test(str);
  };


  const isValidUser = (str:any) => {
    return str && str.length > 0 && /^[a-zA-Z]+$/.test(str);
  };

  const isValidPath = (path:any) => {
    const pathRegex = /^(\/[^\/ ]*)+\/?$/;
    return pathRegex.test(path);
  };

  const isValidUrl = (url:any) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return url && (url.startsWith('http://') || url.startsWith('https://') || ipRegex.test(url));
  };

  if (!isValidUrl(sshData.url)) {
    newError.url = 'Url is required and must be a valid URL or IP address.';
  }
  if (!isValidUser(sshData.username)) {
    newError.username = 'Username is required, must be alphabetic and cannot contain digits.';
  }
  if (checkboxSsh === false && (!containsRequiredCharsForPassword(sshData.password))) {
    newError.password = 'Password is required, must be alphanumeric, and contain at least one digit.';
  }
  if (!sshData.path_to_wp_installation || !isValidPath(sshData.path_to_wp_installation)) {
    newError.path_to_wp_installation = 'Valid path to wp installation is required.';
  }
  if (!sshData.path_to_wp_content_folder || !isValidPath(sshData.path_to_wp_content_folder)) {
    newError.path_to_wp_content_folder = 'Valid path to wp-content folder is required.';
  }
  if (checkboxSsh === true && (!sshData.private_key || !/^-{0,}BEGIN\s+[A-Z\s]+-----/.test(sshData.private_key))) {
    newError.private_key = 'Invalid Private key';
  }
  // if(checkboxSsh === true && (!sshData.paraphase)){
  //   newError.paraphase = "Please Enter Valid Paraphase"
  // }
  // if (!sshData.subtype) {
  //   newError.subtype = 'Subtype is required.';
  // }
  // if (!sshData.meta) {
  //   newError.meta = 'Meta is required.';
  // }
  if (!isValidPremiumUsername(sshData.premium_username)) {
    newError.premium_username = 'Enter premium username, must be alphanumeric, and cannot contain digits.';
  }
  if (!containsRequiredCharsForPremiumPassword(sshData.premium_password)) {
    newError.premium_password = 'Enter premium password, must be alphanumeric, and contain at least one digit.';
  }
  // Uncomment and adjust this as needed
  // if (!sshData.paraphase) {
  //   newError.paraphase = 'Paraphase is required.';
  // }
  console.log("newError" ,sshData,checkboxSsh)
  setSshError(newError);
  return Object.keys(newError).length === 0;
};




//  const validateSsh = () => {
//   let newError: any = {};
//   if (!sshData.url) {
//     newError.url = 'Url is required.';
//   }
//   if (!sshData.username) {
//     newError.username = 'Username is required.';
//   }
//   if (!sshData.password) {
//     newError.password = 'Password is required.';
//   }
//   if (!sshData.path_to_wp_installation) {
//     newError.path_to_wp_installation = 'Path to wp installation is required.';
//   }
//   if (!sshData.path_to_wp_content_folder) {
//     newError.path_to_wp_content_folder = 'Path to wp-content folder is required.';
//   }
//   if (!sshData.private_key) {
//     newError.private_key = 'File is required.';
//   }
//   if (!sshData.subtype) {
//     newError.subtype = 'Subtype is required.';
//   }
//   if (!sshData.meta) {
//     newError.meta = 'Meta is required.';
//   }
//   if(!sshData.premium_username){
//     newError.premium_username = 'Enter premium username'
//   }
//   if(!sshData.premium_username) {
//     newError.premium_password = 'Enter premium password'
//   }
//   // if(!sshData.paraphase) {
//   //   newError.paraphase="par"
//   // }
//   setSshError(newError);
//   return Object.keys(newError).length === 0;
// };
console.log("fgfgh",premiumData)

  const versionDownload = (e: any,name:string) => {
    if(e.fusepress_path !== null){
    try{
      console.log("e",e)
    const parsedUrl = removeStaticPortion(e.fusepress_path);
    console.log("parsedurl",)
    const getName = `${e.slug}-${name === "version" ?e.version:e.plugin_version}.zip`
    const constructrelative = `${parsedUrl.split('/')[0]}/${e.type}/${e.slug}/${getName}`
    // const getName = parsedUrl.split('/')[parsedUrl.split('/').length -1]
    const packageBase64 = Buffer.from(constructrelative).toString('base64')

    axios({
      url: `${process.env.REACT_APP_AUTH_NODE_URL}/api/download/download-package?exp_key=${packageBase64}&bucket=fusepress&user_id=${userdetails.id}&filename=${getName}`,
      method: 'GET',
      responseType: 'blob',
      auth:{
        username:premiumData.Username,
        password: premiumData.password,
      }
    }).then((res1:any) => {
      const blob = new Blob([res1.data], { type: 'zip' });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', getName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    } catch(err){
      toast.error(`something went wrong`)
    }

    }
  }

  const fillSSHDetails = (event:React.ChangeEvent<HTMLSelectElement>):void => {
      const evalue = event.target.value
      if(sshData1.length > 0){
        const filteredObject = sshData1.find((ik) => {return Number(ik.id) === Number(evalue)})
        if(filteredObject !== undefined){
          setCheckboxSsh(filteredObject.subtype === 'password'?false:true)
          setSshData({
              url: filteredObject.url,
              username: filteredObject.user,
              password: '',
              premium_username: filteredObject.premium_username,
              premium_password: '',
              path_to_wp_installation: '',
              path_to_wp_content_folder: '',
              private_key: '',
              paraphrase: '',
              subtype: filteredObject.subtype,
              meta: '',
              text:''
          })
        }
      }
  }

  if (queueData === null) {
    return <Loader />;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {pathname === '/deploy-pipeline' && (
        <Steps
          enabled={stepsEnabled}
          steps={ReviewQueueSteps}
          onExit={(e: any) => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
          }}
          onAfterChange={() => {
            const tooltip = document.querySelector('.introjs-tooltip');
            if (tooltip) {
              const footer = document.createElement('div');
              footer.classList.add('customTooltipFooter');
              footer.innerHTML = `
                <span style="font-size:small; color:red;">NOT HELPFUL?&nbsp;
                  <a href="https://your-documentation-link.com" style="font-size:small; color:red;" target="_blank" rel="noopener noreferrer">
                    Check out the full documentation.
                  </a>
                </span>

              `;
              // Append the footer only if it's not already appended
              if (!tooltip.querySelector('.customTooltipFooter')) {
                tooltip.appendChild(footer);
              }
            }
          }}

        />
      )}

      <main className='main'>
      {/* <div style={{marginLeft:'60px'}}>
      <button type='button' className='cblue_btn back_btn' onClick={goBack}>
                            <span className='back_btn_icon' style={{marginRight:'5px'}}>
                              <img className='img-fluid' src={backarrow} alt='back-btn' />
                            </span>
                            <span>Back</span>
                        </button>
      </div> */}
        <div className='container review-queue-first'>
          <div className='w-full d-flex justify-content-end'>
            <button type='button' className='roundedButton' onClick={() => onActivateIntro()}>
              <FontAwesomeIcon icon={faQuestion} />
            </button>
          </div>
          <div className='review_queue'>

          </div>
          <div>
            {storeQueue.length > 0 && (
              <div className='review_queue'>
                <div className='accordion accordion-flush' id='accordionFlushExample'>
                  {storeQueue
                    .filter((i: any) => {
                      if (window.location.search.length > 0) {
                        if (
                          i.project.project_id === parseInt(queryString.get('project') as any) &&
                          i.enviornments.id === parseInt(queryString.get('environment') as any)
                        ) {
                          console.log('i', i);
                          return i;
                        } else {
                          return null;
                        }
                      } else {
                        return i;
                      }
                    })
                    .map((k: any, index: number) => {
                      const isValid = isValidJSONString(k?.enviornments?.meta, k);
                      if (isValid) {
                        const meta = JSON.parse(k.enviornments.meta !== null ? k.enviornments.meta! : '{}');

                        return (
                          <>
                            <div className='accordion-item'>
                              <h2 className='accordion-header' id={`wp_connectorone_${index}`}>
                                <button
                                  className='accordion-button collapsed'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={`#wp_connector_${index}`}
                                  aria-expanded='false'
                                >
                                  {k.project.project_name} - environment {k.enviornments.name} &nbsp;{' '}
                                  <strong>
                                    {Object.keys(meta).length > 0
                                      ? meta.type === 'composer'
                                        ? t('deploy.tags.composer')
                                        : t('deploy.tags.wp-connector')
                                      : t('deploy.tags.not-configure')}
                                  </strong>
                                </button>
                              </h2>
                              <div
                                id={`wp_connector_${index}`}
                                className='accordion-collapse collapse'
                                aria-labelledby={`wp_connectorone_${index}`}
                                data-bs-parent='#accordionFlushExample'
                              >
                                <div className='accordion-body'>
                                  <div className='table-responsive'>
                                    <table className='table queue_table'>
                                      <thead>
                                        <tr>
                                          <th style={{ paddingTop: '15px', paddingBottom: '15px' }}>{t('deploy.table.package-name')}</th>
                                          <th style={{ paddingTop: '15px', paddingBottom: '15px' }}>{t('deploy.table.current-version')}</th>
                                          <th style={{ paddingTop: '15px', paddingBottom: '15px' }}>{t('deploy.table.new-version')}</th>
                                          <th style={{ paddingTop: '15px', paddingBottom: '15px' }}>Action</th>
                                          <th style={{ paddingTop: '15px', paddingBottom: '15px' }}>{t('deploy.table.status')}</th>
                                          <th style={{ paddingTop: '15px', paddingBottom: '15px' }}></th>
                                          {/* <th style={{ paddingTop: "15px", paddingBottom: "15px" }}>Change status</th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {k.plugins.map((x: any) => {
                                          return (
                                            <tr>
                                              <td><span title={(x.name !=="")  ?x.name :x.slug}>{(x.name !=="")  ?x.name.substring(0,15) + " ..." :x.slug}</span></td>
                                              <td onClick={() => versionDownload(x,"version")}>v{x.version}</td>
                                              <td onClick={() => versionDownload(x,"plugin_version")}>v{x.plugin_version}</td>
                                              <td><span style={{color:getColor(x.action)}}>{x.action}</span></td>
                                              <td><span style={{color:getColor(x.action)}}>{x.action}</span></td>
                                              <td></td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='container-fluid p-0 '>
                              {Object.keys(meta).length > 0 ? (
                                <>
                                  {meta.type === 'wp' ? (
                                    <div
                                      className='row my-4'
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setDeployTypeString(e.target.value)
                                      }
                                    >
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio1'
                                          value='deploy-using-composer-file'
                                          disabled
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio1'>
                                          {t('deploy.methods.composer-json')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio2'
                                          value='deploy-updating-composer-files-in-repository'
                                          disabled
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio2'>
                                        {t('deploy.methods.composer-repository')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio3'
                                          value='deploy-using-ssh'
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio3'>
                                        {t('deploy.methods.ssh')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio4'
                                          value='deploy-using-ftp'
                                          disabled
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio4'>
                                        {t('deploy.methods.ftp')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio5'
                                          value='deploy-using-wp-connector-plugin'
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio5'>
                                        {t('deploy.methods.rest-api')}
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className='row my-4'
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setDeployTypeString(e.target.value)
                                      }
                                    >
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio1'
                                          value='deploy-using-composer-file'
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio1'>
                                        {t('deploy.methods.composer-json')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio2'
                                          value='deploy-updating-composer-files-in-repository'
                                          disabled
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio2'>
                                        {t('deploy.methods.composer-repository')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio3'
                                          value='deploy-using-ssh'
                                          // disabled
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio3'>
                                        {t('deploy.methods.ssh')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio4'
                                          value='deploy-using-ftp'
                                          disabled
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio4'>
                                        {t('deploy.methods.ftp')}
                                        </label>
                                      </div>
                                      <div className='form-check form-check-inline col-auto me-4 lh-sm'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='inlineRadioOptions'
                                          id='inlineRadio5'
                                          value='deploy-using-wp-connector-plugin'
                                          disabled
                                        />
                                        <label className='form-check-label' htmlFor='inlineRadio5'>
                                        {t('deploy.methods.rest-api')}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : null}
                              {Object.keys(meta).length > 0 ? (
                                <div className='d-flex justify-content-end'>
                                  <button
                                    className='orange_btn ms-1'
                                    style={{marginTop:"-60px"}}
                                    onClick={() =>
                                      redirectToDeploy(
                                        k,
                                        meta.type === 'wp' ? 'wp_connector_queue' : 'composer_queue',
                                        k.enviornments
                                      )
                                    }
                                  >
                                    {t('deploy.buttons.deploy')}
                                  </button>
                                </div>
                              ) : (
                                <div className='d-flex justify-content-end'>
                                  <button className='orange_btn ms-1' style={{marginTop:"-60px"}} onClick={() => redirectToProject(k)}>
                                  {t('deploy.buttons.return-env')}
                                  </button>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      }
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
        <CommonModal className='composer-model' show={show} onHide={handleClose} animation={false} size='lg'>
          <CommonModal.Header style={{width:"100%"}}>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <h1 className='text-white'>SSH Details</h1>
              <FontAwesomeIcon icon={faClose} onClick={handleClose} style={{ color: 'white' }} size='lg' />
            </div>
            <div>
            <div className='input-group mb-3' style={{width:"40%"}}>
                <span className='input-group-text'>Select ssh</span>
                <select className="environment-select form-select" style={{width:"30%"}} onChange={(e) => fillSSHDetails(e)}>
                  <option value="">Select</option>
                  { sshData1.length > 0 && sshData1.map((ik:any) => {
                    return(
                      <option value={ik.id}>{ik.url}</option>
                    )
                  })

                  }
                </select>
             </div>

            </div>
          </CommonModal.Header>
          <CommonModal.Body>

            <div className='tab-content'>
                  <form className='addproject' onSubmit={e => onProjectConfigStep(e, 'ssh')}>
                    <div className="alert alert-warning" role="alert">
                        <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;&nbsp;{t('deploy.warning-message')}
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('deploy.ssh.address')}</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='https://'
                        name='url'
                        value={sshData.url}
                        onChange={e => {
                          setSshData({
                            ...sshData,
                            url: e.target.value,
                          });
                          if (sshError.url) {
                            setSshError((prevErrors: any) => ({ ...prevErrors, url: null }));
                          }
                        }}
                      />
                    </div>
                    {sshError.url && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.url}</div>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>{t('deploy.ssh.user')}</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter User'
                        name='username'
                        value={sshData.username}
                        onChange={e => {
                          setSshData({
                            ...sshData,
                            username: e.target.value,
                          });
                          if (sshError.username) {
                            setSshError((prevErrors: any) => ({ ...prevErrors, username: null }));
                          }
                        }}
                      />
                    </div>
                    {sshError.username && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.username}</div>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Premium Username</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter premium username'
                        name='username'
                        value={sshData.premium_username}
                        onChange={e => {
                          setSshData({
                            ...sshData,
                            premium_username: e.target.value,
                          });
                          if (sshError.username) {
                            setSshError((prevErrors: any) => ({ ...prevErrors, premium_username: null }));
                          }
                        }}
                      />
                    </div>
                    {sshError.premium_username && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.premium_username}</div>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Premium Password</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter premium password'
                        name='username'
                        value={sshData.premium_password}
                        onChange={e => {
                          setSshData({
                            ...sshData,
                            premium_password: e.target.value,
                          });
                          if (sshError.username) {
                            setSshError((prevErrors: any) => ({ ...prevErrors, premium_password: null }));
                          }
                        }}
                      />
                    </div>
                    {sshError.premium_password && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.premium_password}</div>}
                    <div
                      className='tab-pane fade text-white active show'
                      id='pills-composer'
                      role='tabpanel'
                      aria-labelledby='pills-composer-tab'
                    >
                      <div className='flex justify-content-start'>
                        <div className='login_register' style={{ justifyContent: 'start' }}>
                          <span>{t('deploy.ssh.password')}</span>
                          <label className='switch'>
                            <input
                              type='checkbox'
                              checked={checkboxSsh}
                              id='select_login_screen'
                              name='select_login_screen'
                              onChange={e => setCheckboxSsh(!checkboxSsh)}
                            />
                            <span className='slider round'></span>
                          </label>
                          <span>{t('deploy.ssh.public-key')}</span>
                        </div>
                      </div>
                      {!checkboxSsh ? (
                        <div>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>{t('deploy.ssh.password')}</span>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter Password'
                              name='password'
                              value={sshData.password}
                              onChange={e => {
                                setSshData({
                                  ...sshData,
                                  password: e.target.value,
                                });
                                if (sshError.password) {
                                  setSshError((prevErrors: any) => ({ ...prevErrors, password: null }));
                                }
                              }}
                            />
                          </div>
                          {sshError.password && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.password}</div>}
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>{t('deploy.ssh.path-wordpress-install')}</span>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter path'
                              name='path_to_wp_installation'
                              value={sshData.path_to_wp_installation}
                              onChange={e => {
                                setSshData({
                                  ...sshData,
                                  path_to_wp_installation: e.target.value,
                                });
                                if (sshError.path_to_wp_installation) {
                                  setSshError((prevErrors: any) => ({ ...prevErrors, path_to_wp_installation: null }));
                                }
                              }}
                            />
                          </div>
                          {sshError.path_to_wp_installation && (
                            <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_installation}</div>
                          )}
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>{t('deploy.ssh.wp-content-path')}</span>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter path'
                              name='path_to_wp_content_folder'
                              value={sshData.path_to_wp_content_folder}
                              onChange={e => {
                                setSshData({
                                  ...sshData,
                                  path_to_wp_content_folder: e.target.value,
                                });
                                if (sshError.path_to_wp_content_folder) {
                                  setSshError((prevErrors: any) => ({
                                    ...prevErrors,
                                    path_to_wp_content_folder: null,
                                  }));
                                }
                              }}
                            />
                          </div>
                          {sshError.path_to_wp_content_folder && (
                            <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_content_folder}</div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>{t('deploy.ssh.private-key')}</span>
                            <textarea
                              rows={10}
                              className='form-control'
                              placeholder='Enter path'
                              name='path_to_wp_installation'
                              value={sshData.private_key}
                              onChange={e => {
                                setSshData({
                                  ...sshData,
                                  private_key: e.target.value,
                                });
                                if (sshError.private_key) {
                                  setSshError((prevErrors: any) => ({ ...prevErrors, private_key: null }));
                                }
                              }}
                            ></textarea>
                          </div>
                          {sshError.private_key && <div style={{ color: 'red', fontSize: '14px' }}>{sshError.private_key}</div>}
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>{t('deploy.ssh.paraphase')}</span>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter your paraphase'
                              name='paraphase'
                              value={sshData.paraphase}
                              onChange={e => {
                                setSshData({
                                  ...sshData,
                                  paraphase: e.target.value,
                                });

                              }}
                            />
                          </div>
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>{t('deploy.ssh.path-wordpress-install')}</span>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter path'
                              name='path_to_wp_installation'
                              value={sshData.path_to_wp_installation}
                              onChange={e => {
                                setSshData({
                                  ...sshData,
                                  path_to_wp_installation: e.target.value,
                                });
                                if (sshError.path_to_wp_installation) {
                                  setSshError((prevErrors: any) => ({ ...prevErrors, path_to_wp_installation: null }));
                                }
                              }}
                            />
                          </div>
                          {sshError.path_to_wp_installation && (
                            <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_installation}</div>
                          )}
                          <div className='input-group mb-3'>
                            <span className='input-group-text'>{t('deploy.ssh.wp-content-path')}</span>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter path'
                              name='path_to_wp_content_folder'
                              value={sshData.path_to_wp_content_folder}
                              onChange={e => {
                                setSshData({
                                  ...sshData,
                                  path_to_wp_content_folder: e.target.value,
                                });
                                if (sshError.path_to_wp_content_folder) {
                                  setSshError((prevErrors: any) => ({
                                    ...prevErrors,
                                    path_to_wp_content_folder: null,
                                  }));
                                }
                              }}
                            />
                          </div>
                          {sshError.path_to_wp_content_folder && (
                            <div style={{ color: 'red', fontSize: '14px' }}>{sshError.path_to_wp_content_folder}</div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className='d-flex justify-content-end my-5'>
                    {/* <button className='orange_btn mx-1' onClick={handleModal}>{t('deploy.ssh.buttons.deploy')}
                    </button> */}
                      <button className='orange_btn mx-1'>{t('deploy.ssh.buttons.deploy')}</button>
                    </div>
                  </form>
                </div>
          </CommonModal.Body>
        </CommonModal>
        <CommonAlert
            onHide={setOpenConcentAlert}
            show={openConcentAlert}
            message={'Are you sure you want to save ssh details to fusepress'}
            clickFunc={() => saveAndPerformSshAction(true,"ssh")}
            onClickReject={() => saveAndPerformSshAction(false,"ssh")}
          />
        {/* <CommonModal  onHide={setIsModalOpen} show={isModalOpen}>
        <CommonModal.Body>
        <div className="logo" style={{color:"white"}}>

                 <h5>Do You Want To Store SSH Details?</h5>
                 <div className='model' style={{display:'flex'}}>
                 <button className='orange_btn' style={{}}>Yes</button>
                 <button className='orange_btn' style={{marginLeft:'10px'}}>No</button>
                 </div>
              </div>
        </CommonModal.Body>
</CommonModal> */}
      </main>
    </>
  );
}

export default ReviewQueue;
