/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import fusepress_logo from '../img/fusepress_logo.png';
import project_list from '../img/projects.png';
// import startup from '../img/start-up.png';
import stores from '../img/store.png';
// import tutorial from '../img/Tutorial.png';
import docs from '../img/docs.png';
import support from '../img/support.png';
import billing from '../img/billing.png';
import account_setting from '../img/account_setting.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faCircleExclamation, faQuestion, faUser } from '@fortawesome/free-solid-svg-icons';
import { useProjectQuery } from '../features/api';
import { FusePressLogo } from '../utils/extras';
import { Steps } from 'intro.js-react';
import axios from 'axios';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import { fetchUserDetails } from '../features/userDetailsSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../features/hooks';
import { toast } from 'react-toastify';
import Notification from './Notification';
import { syncWpEnvironments } from '../features/syncWpEnvironment';
import { Link } from 'react-router-dom';
import { useDeleteQueryString, useManageQueryString, useUpdateQueryString } from '../utils/queryStringFunc';
import CommonAlert from './CommonAlert';


const Home:React.FC =() => {


  const dispatch =useDispatch();
  // const selector =useSelector();
  useEffect(() => {
    dispatch(fetchUserDetails());


  }, [dispatch]);

  // const userDetails=JSON.parse(localStorage.getItem('userDetails') || '{}');
  const [wordpressLoginUrl, setWordpressLoginUrl] = useState<string>('');
  const token = localStorage.getItem('token') || '';
  // const userdetail = localStorage.getItem('userDetails') || '';
  // const { data: userdetails, isLoading: accountLoad } = useUserDetailsQuery({});

  // const { data: userdetail } = useSelector(selectUserDetailsState)
  const {t, i18n} = useTranslation()
  const { data:userdetails } = useAppSelector((state:any) => state.userDetails);
const queryString = new URLSearchParams(window.location.search)
const location = useLocation();
const deleteQueryString = useDeleteQueryString();
const updateQueryString = useUpdateQueryString();
const manageQueryString = useManageQueryString();

// useEffect(() => {

//   console.log('hello',location.search)
//   if(location.search.length > 0){
//     if(queryString.get("intro")){
//       setStepsEnabled(true)
//     }
//   }

// },[location.search])

  // console.log("userdetails",userdetails)


  // const { data: userdetails, isLoading:wpLoading } = useAppSelector((state: any) => state.userDetails);
  // const { data: userdetails } = useAppSelector((state: any) => state.userDetails);

  useEffect(() => {
    if (typeof userdetails !== 'undefined') {
      const fetchLoginUrl = async () => {
        try {
          const url = (await loginToWordPress()) || '';
          localStorage.setItem('wordpressloginurl', url);
          setWordpressLoginUrl(url)

        } catch (error) {
          setWordpressLoginUrl('')
          console.error('Error fetching login URL:', error);
        }
      };

      fetchLoginUrl();
    }
  }, [userdetails]);

  const loginToWordPress = async (): Promise<string | null> => {
    try {
      const formData = new FormData();
      formData.append('Email', userdetails.email);
      formData.append('token', token);
      // formData.append("Password",atob(data.password))
      const response = await axios.post(
        `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp-json/signin-user/myaccount`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const redirectUrl = response.data;
      if (redirectUrl === 'Invalid login credentials.') {
        const email = userdetails.email;
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
          const emailBeforeAt = email.substring(0, atIndex);
          axios({
            url: `${process.env.REACT_APP_WORDPRESS_STAGING_URL}/wp/user-sync.php`,
            method: 'GET',
            headers: {},
            params: {
              action: 'get_user_data',
              fname: userdetails.displayName,
              lname: userdetails.displayName,
              username: emailBeforeAt,
              email: userdetails.email,
              role: 'customer',
            },
            data: {},
          })
            .then(res1 => {
              console.log('res1', res1);
            })
            .catch((err1: any) => {
              console.log('err1', err1);
            });
        }
        return redirectUrl;
      } else {
        return redirectUrl;
      }
    } catch (error) {
      console.error('Error during login:', error);
      return null;
    }
  };

  const { data: projectsData } = useProjectQuery({
    data: { 'with-plugins': true },
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const pathname = window.location.pathname;
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);
  const [userDetails, setUserDetails] = useState<any>({});
  const navigate = useNavigate();
  let initialSteps = 0;
  let steps = [
    {
      title: t('intro.home.first.title'),
      position: 'center',
      intro: `<div class="introjs_logo">${FusePressLogo}</div>`,
    },
    // {
    //   title: "Create Project",
    //   element: ".first",
    //   position: "right",
    //   intro: " <p>this is the desc for first div</p>",
    // },
    {
      title: t('intro.home.two.title'),
      element: '.second',
      position: 'right',
      intro: t('intro.home.two.desc'),
    },
    // {
    //   title: "Tutorials",
    //   element: ".third",
    //   position: "right",
    //   intro: " <p>this is the desc for third div</p>",
    // },
    {
      title: t('intro.home.three.title'),
      element: '.four',
      position: 'right',
      intro: t('intro.home.three.desc'),
    },
    {
      title: t('intro.home.four.title'),
      element: '.five',
      position: 'right',
      intro: t('intro.home.four.desc'),
    },
    {
      title: t('intro.home.five.title'),
      element: '.six',
      position: 'right',
      intro: t('intro.home.five.desc'),
    },
    {
      title: t('intro.home.six.title'),
      element: '.seven',
      position: 'right',
      intro: t('intro.home.six.desc'),
    },
    {
      title: t('intro.home.six.title'),
      element: '.eight',
      position: 'right',
      intro: t('intro.home.six.desc'),
    },
  ];
  const [notification,setNotification] = useState(false)

  useEffect(()=>{
    const flag_value:any = localStorage.getItem('welcome_flag');
    const notification_flag = JSON.parse(flag_value);
    setNotification(notification_flag)
  },[notification])

  // useEffect(() => {
  //   if (!localStorage.getItem("IntroToken")) {
  //     localStorage.setItem("IntroToken", "true");
  //     setStepsEnabled(true);
  //   } else {
  //     setStepsEnabled(
  //       localStorage.getItem("IntroToken") === "true" ? true : false
  //     );
  //   }
  // }, []);
  useEffect(() => {
    if (typeof userdetails !== "undefined") {
      setUserDetails(userdetails);
    }
  }, [userdetails]);

  useEffect(() => {
    console.log("hello")

    if(localStorage.getItem("syncLock") !== null){
      const counter = localStorage.getItem("syncLock")
      if(counter === "0"){
        dispatch(syncWpEnvironments({flag:"all"}))
        localStorage.setItem("syncLock","1")
      }
    } else {
      localStorage.setItem("syncLock","0")
    }

  },[dispatch])

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'home-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'home-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'home-screen', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        // manageQueryString(homeScreen.status)
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'home-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'home-screen';
      });
      // if(index < 0){
      //    const homeScreen = {screen:"home-screen", status:'false'}
      //     array.push(homeScreen)
      //     setStepsEnabled(
      //       homeScreen.status === 'true' ? true : false
      //     );
      //     localStorage.setItem("introjs",JSON.stringify(array!))
      // }
      if (index >= 0) {
        array[index].status = 'false';
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };
  const onbeforeExit = () => {
    console.log('true');
  };
  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'home-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    // window.location.replace(process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout');
    // navigate('/auth');
    localStorage.setItem("syncLock","0")
    const logoutWindow = window.open(
      process.env.REACT_APP_WORDPRESS_STAGING_URL + '/wp/wp-login.php?action=logout',
      '_blank'
    );
    setTimeout(() => {
      if (logoutWindow) {
        logoutWindow.close();
        window.location.reload();
        navigate('/auth');
      }
    }, 2000);
  };
  const changeLanguage = (text:string):void => {
    localStorage.setItem('language',text)
    // const lang = i18n.language === "en"? "ro" : "en"
    i18n.changeLanguage(text)
  }
  const redirecttoAccounts = () => {
    console.log("hello",wordpressLoginUrl)
    if(wordpressLoginUrl.length > 0){
      window.open(wordpressLoginUrl,"_blank")
    }else {
      toast.error("something went wrong")
    }
  }
  console.log("wordpressLoginUrl",wordpressLoginUrl)

  if (userdetails === null) {
    return <Loader />;
  }

  // const handleClick = ()=>{
  //   setIsModalOpen(true)
  // }

  // const clickFunc = ()=>{
  //   console.log("Hello from Click")
  // }

  return (
    <>
      {notification && (
        <div className='App'>
          <Notification message={t('intro.home.welcome-to-the-app!')} duration={3000} />
        </div>
      )}

      {pathname === '/' && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          // onExit={() => (stepsEnabled === null ? false : stepsEnabled)}
          onExit={e => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
            tooltipClass: 'customTooltip ',
            // showStepNumbers: true,
          }}
          onBeforeExit={() => onbeforeExit()}
          onAfterChange={() => {
            const tooltip = document.querySelector('.introjs-tooltip');
            if (tooltip) {
              const footer = document.createElement('div');
              footer.classList.add('customTooltipFooter');
              footer.innerHTML = `
                <span style="font-size:small; color:red;">NOT HELPFUL?&nbsp;
                  <a href="https://your-documentation-link.com" style="font-size:small; color:red;" target="_blank" rel="noopener noreferrer">
                    Check out the full documentation.
                  </a>
                </span>

              `;
              // Append the footer only if it's not already appended
              if (!tooltip.querySelector('.customTooltipFooter')) {
                tooltip.appendChild(footer);
              }
            }
          }}
          // onBeforeExit={() => {
          //   let data =
          //     stepsEnabled !== null &&
          //     window.confirm("Don't want to show this again then press Ok");
          //   stepsEnabled !== null &&
          //   data &&
          //   localStorage.setItem("IntroToken", "false");
          // }}
        />
      )}
      {/* <div className='d-block'>

      </div> */}
      {
        /* <div className='d-block' style={{position:"absolute",top:"20px",right:"20px"}}>
            <button className='orange_btn mx-2' onClick={() => changeLanguage("en")}>EN</button>
            <button className='cblue_btn' onClick={() => changeLanguage("ro")}>RO</button>
        </div> */

        <div className='d-block' style={{ position: 'absolute', top: '20px', right: '20px', width: '100%' }}>
          <div className='p-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button type='button' className='roundedButton me-2' onClick={() => onActivateIntro()}>
              <FontAwesomeIcon icon={faQuestion} />
            </button>
            {/* <button className='orange_btn me-2'>{t('home.buttons.skip-the-tour')}</button> */}
            {/* <button type='button' className='orange_btn mx-2' onClick={() => setIsMOpen(true)}>
            {t('Generate Password')}
          </button> */}
            {/* <button type='button' className='orange_btn me-2' onClick={() => logout()}>
              {t('home.buttons.log-out')}
            </button> */}

            <select
              className='language-select environment-select form-select '
              onChange={e => changeLanguage(e.target.value)}
              value={i18n.language}
              style={{
                padding: '8px 30px 8px 10px',
                fontSize: '16px',
                backgroundColor: '#f08921',
                color: 'white',
                appearance: 'none',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                transition: 'color 0.3s',
                borderRadius: '5px',
                maxWidth: '65px',
                width: '100%',
              }}
            >
              <option
                value='en'
                style={{
                  color: '#f08921',
                  border: 'none',
                  borderRadius: '5px',
                }}
              >
                EN
              </option>
              <option
                value='ro'
                style={{
                  color: '#f08921',
                  border: '2px solid red',
                  borderRadius: '5px',
                }}
              >
                RO
              </option>
              <option
                value='es'
                style={{
                  color: '#f08921',
                  borderRadius: '5px',
                }}
              >
                ES
              </option>
              <option
                value='it'
                style={{
                  color: '#f08921',
                  borderRadius: '5px',
                }}
              >
                IT
              </option>
              <option
                value='sv'
                style={{
                  color: '#f08921',
                  borderRadius: '5px',
                }}
              >
                SV
              </option>
              <option
                value='el'
                style={{
                  color: '#f08921',
                  borderRadius: '5px',
                }}
              >
                EL
              </option>
              <option
                value='de'
                style={{
                  color: '#f08921',
                  borderRadius: '5px',
                }}
              >
                DE
              </option>
              <option
                value='nl'
                style={{
                  color: '#f08921',
                  borderRadius: '5px',
                }}
              >
                NL
              </option>
            </select>
            <div className="dropdown">
      <div className="icon-container">
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className="dropdown-content">
        <span onClick={() => redirecttoAccounts()}>Profile</span>
        <Link to={'/wishlist'}>Wishlist</Link>
        <Link to={`${process.env.REACT_APP_WORDPRESS_STAGING_URL}/support/`}>
        Support
        </Link>
        <Link to='/billing'>
        Billing
        </Link>
        <span onClick={() => logout()}>Logout</span>

      </div>
    </div>
          </div>
        </div>
      }

      <div className='home_main'>
        <div className='home_log'>
          <a href='/'>
            <div>
              <img src={fusepress_logo} alt='' />
            </div>
          </a>
        </div>
        {userdetails !== null && (
          <div className='d-flex align-items-center justify-content-center mb-2 gap-2'>
            <h1 className='home_title m-0'>
              {t('welcome')},{' '}
              {userDetails?.fname &&
                (userDetails.provider !== null
                  ? userDetails?.fname + ' '
                  : userDetails?.fname + ' ' + userDetails?.lname)}
            </h1>
            {/* <button type='button' className='roundedButton me-2' onClick={() => onActivateIntro()}>
              <FontAwesomeIcon icon={faQuestion} />
            </button> */}
          </div>
        )}

        <div className='linkdata'>
          <div className='container'>
            <div className='row'>
              {/* <div className="col-md-6 mb-3">
              <NavLink className="listgrid first" to="/projects/import">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-10">
                      <div className="list_title">
                        <div className="d-flex gx-2 align-items-center">
                          <img src={startup} className="me-3" alt="" />
                          <h2 className="text-white">Create a Project</h2>
                        </div>

                        <p className="listdesc">
                          Create or import a new project into your account
                        </p>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="list_icn">
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div> */}
              <div className='col-md-6 mb-3'>
                <NavLink className='listgrid second' to='/store'>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col-10'>
                        <div className='list_title'>
                          <div className='d-flex gx-2 align-items-center'>
                            <img src={stores} className='me-3' alt='' />
                            <h2 className='text-white'>{t('home.store-title')}</h2>
                          </div>

                          <p className='listdesc'>{t('home.store-description')}</p>
                        </div>
                      </div>
                      <div className='col-2'>
                        <div className='list_icn'>
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
              {/* <div className="col-md-6 mb-3">
              <NavLink className="listgrid third" to="/projects">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-10">
                      <div className="list_title">
                        <div className="d-flex gx-2 align-items-center">
                          <img src={tutorial} className="me-3" alt="" />
                          <h2 className="text-white">Tutorials</h2>
                        </div>

                        <p className="listdesc">
                          Take a guided tour of the platform
                        </p>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="list_icn">
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div> */}
              <div className='col-md-6 mb-3'>
                <NavLink className='listgrid four' to='/projects'>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col-10'>
                        <div className='list_title'>
                          <div className='d-flex gx-2 align-items-center'>
                            <img src={project_list} className='me-3' alt='' />
                            <h2 className='text-white'>{t('home.project-title')}</h2>
                          </div>

                          <p className='listdesc'>
                            {t('home.project-description', {
                              projectLength: projectsData?.projects?.length > 0 ? projectsData?.projects?.length : '0',
                            })}
                          </p>
                        </div>
                      </div>
                      <div className='col-2'>
                        <div className='list_icn'>
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='col-md-6 mb-3 '>
                {/* <NavLink className='listgrid five' to={wordpressLoginUrl || ''} target='_blank'> */}
                <div className='listgrid five' onClick={() => redirecttoAccounts()}>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col-10'>
                        <div className='list_title'>
                          <div className='d-flex gx-2 align-items-center'>
                            <img src={account_setting} className='me-3' alt='' />
                            <h2 className='text-white'>{t('home.account-title')}</h2>
                          </div>

                          <p className='listdesc'>{t('home.account-description')}</p>
                        </div>
                      </div>
                      <div className='col-2'>
                        <div className='list_icn'>
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </NavLink> */}
              </div>
              <div className='col-md-6 mb-3'>
                <NavLink className='listgrid six' to='/billing'>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col-10'>
                        <div className='list_title'>
                          <div className='d-flex gx-2 align-items-center'>
                            <img src={billing} className='me-3' alt='' />
                            <h2 className='text-white'>{t('home.billing-title')}</h2>
                          </div>

                          <p className='listdesc'>{t('home.billing-description')}</p>
                        </div>
                      </div>
                      <div className='col-2'>
                        <div className='list_icn'>
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='col-md-6 mb-3'>
                <NavLink className='listgrid seven' to={`${process.env.REACT_APP_WORDPRESS_STAGING_URL}/support/`}>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col-10'>
                        <div className='list_title'>
                          <div className='d-flex gx-2 align-items-center'>
                            <img src={support} className='me-3' alt='' />
                            <h2 className='text-white'>{t('home.support-title')}</h2>
                          </div>

                          <p className='listdesc'>{t('home.support-description')}</p>
                        </div>
                      </div>
                      <div className='col-2'>
                        <div className='list_icn'>
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='col-md-6 mb-3'>
                <NavLink className='listgrid eight' to={`${process.env.REACT_APP_WORDPRESS_STAGING_URL}/documentation`} target='_blank'>
                  <div className='container'>
                    <div className='row align-items-center'>
                      <div className='col-10'>
                        <div className='list_title'>
                          <div className='d-flex gx-2 align-items-center'>
                            <img src={docs} className='me-3' alt='' />
                            <h2 className='text-white'>{t('home.documentation-title')}</h2>
                          </div>

                          <p className='listdesc'>{t('home.documentation-description')}</p>
                        </div>
                      </div>
                      <div className='col-2'>
                        <div className='list_icn'>
                          <FontAwesomeIcon icon={faChevronCircleRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='text-center p-2' style={{display:'flex' , justifyContent:'center'}}>
          <button className='orange_btn'>{t('home.buttons.skip-the-tour')}</button>

          <button type='button' className='orange_btn mx-2' onClick={() => logout()}>
            {t('home.buttons.log-out')}
          </button>


          <button type='button' className='roundedButton' onClick={() => onActivateIntro()}>
            <FontAwesomeIcon icon={faQuestion}/>
          </button>
        </div> */}
        <div>
          {/* <select className='environment-select form-select bg-none' value={i18n.language} onChange={(e) => changeLanguage(e)}>
              <option value='en'>EN</option>
              <option value='ro'>RO</option>
        </select> */}
        </div>
      </div>
      {/* {/* <button onClick={handleClick}>Click Me</button> */}
      {/* <CommonAlert
        onHide={setIsModalOpen}
        show={isModalOpen}
        message={'are you sure you want to delete it'}
        clickFunc={clickFunc}
      /> */}
    </>
  );
}

export default Home;
