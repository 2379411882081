/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import data from '../data/composer.json';
import { usePutProjectMutation } from '../features/api';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'intro.js-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const NewProject:React.FC = () =>  {
  const navigate = useNavigate();
  const [type, setType] = useState('');
  const [CreateProject] = usePutProjectMutation();
  const [isProjectAdd, setIsProjectAdd] = useState(false);
  const [formData, setFormData] = useState({
    projectname: '',
    description: '',
    environment: '',
    composerData: '',
  });
  useEffect(() => {
    setFormData({
      ...formData,
      composerData: JSON.stringify(data),
    });
  }, []);
  const pathname = window.location.pathname;
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);

  let initialSteps = 0;
  let NewProjectSteps = [
    {
      title: 'Welcome to New Project screen',
      // element: ".new-project-first",
      position: 'right',
      intro: ' <p>This is the desc for first div</p>',
    },
    {
      title: 'New Project',
      element: '.new-project-first',
      position: 'left',
      intro: ' <p>This is the desc for first div</p>',
    },
    {
      title: 'Existing project',
      element: '.new-project-second',
      position: 'left',
      intro: ' <p>This is the desc for first div</p>',
    },
  ];

  const handleChange = (event: any) => {
    const { name, value, type } = event.target;
    const isCheckbox = type === 'checkbox';

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: any, projectType: any) => {
    const wpMeta = {
      type: 'unassigned',
    };
    event.preventDefault();
    let data1 = {
      name: formData.projectname,
      slug: formData.projectname,
      description: formData.description,
      environment: formData.environment,
      project_type: 0,
      // composer:formData.composerData
      siteurl: '',
      username: '',
      app_password: '',
      meta: JSON.stringify(wpMeta),
      composer_require_array: JSON.stringify([]),
    };
    let payload: any = {
      id: 1,
      data: data1,
    };
    const formData1 = new FormData();

    // Append the 'id' parameter as a field
    formData1.append('id', payload.id);

    // Append fields from the 'data' parameter
    for (const key in payload.data) {
      if (payload.data.hasOwnProperty(key)) {
        formData1.append(key, payload.data[key]);
      }
    }

    axios({
      url: `${process.env.REACT_APP_API_URL}/api/team/${payload.id}/project`,
      method: 'POST',
      data: formData1,
      headers: {
        // Accept: "multipart/form-data",
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(res1 => {
        if (Object.keys(res1).length > 0) {
          if (res1.data.hasOwnProperty('error')) {
            toast.error(`${res1.data.message}`);
          } else {
            toast.success(`${res1.data.message}`);
          }
        }
      })
      .catch(err1 => {
        console.log('err1', err1);
      });

    // let data1 = {
    //   name: wpprojectDetails.projectname,
    //   slug: wpprojectDetails.projectname,
    //   description: wpprojectDetails.description,
    //   environment: wpprojectDetails.environment,
    //   project_type: "2",
    //   siteurl: wpprojectDetails.siteurl,
    //   username: wpprojectDetails.username,
    //   app_password: wpprojectDetails.app_password,
    //   meta: wpMeta,
    //   composer_require_array: [],
    // };
    // let payload = {
    //   id: 1,
    //   data: data1,
    // };
    // CreateProject(payload)
    //   .then((res:any) => {
    //     if(Object.keys(res).length > 0) {
    //       if(res.data.hasOwnProperty("error")){
    //         toast.error(`${res.data.message}`);
    //       }
    //       else{
    //         toast.success(`${res.data.message}`);
    //         setIsProjectAdd(true);
    //       }
    //     }
    //   })
    //   .catch((err:any) => {
    //     toast.error(`${err}`);
    //   })
  };

  const gotoAddproject = () => {
    navigate(`/projects/add/?type=${type}`);
  };

  const handleClick = (e: any) => {
    setType(e.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'new-project-screen', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'new-project-screen';
      });
      if (index < 0) {
        const homeScreen = { screen: 'new-project-screen', status: 'true' };

        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);
  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'new-project-screen';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'new-project-screen';
      });

      if (index >= 0) {
        array[index].status = 'false';
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };

  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'new-project-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  return (
    <>
      {pathname === '/projects/import' && (
        <Steps
          enabled={stepsEnabled}
          steps={NewProjectSteps}
          // onExit={() => (stepsEnabled === false ? false : stepsEnabled)}
          onExit={e => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
            // showStepNumbers: true,
          }}
          onAfterChange={() => {
            const tooltip = document.querySelector('.introjs-tooltip');
            if (tooltip) {
              const footer = document.createElement('div');
              footer.classList.add('customTooltipFooter');
              footer.innerHTML = `
                <span style="font-size:small; color:red;">NOT HELPFUL?&nbsp;
                  <a href="https://your-documentation-link.com" style="font-size:small; color:red;" target="_blank" rel="noopener noreferrer">
                    Check out the full documentation.
                  </a>
                </span>

              `;
              // Append the footer only if it's not already appended
              if (!tooltip.querySelector('.customTooltipFooter')) {
                tooltip.appendChild(footer);
              }
            }
          }}
          // onBeforeExit={() => {
          //   let data =
          //     stepsEnabled !== null &&
          //     window.confirm("Don't want to show this again then press Ok");
          //   stepsEnabled !== null &&
          //   data &&
          //   localStorage.setItem("IntroToken", "false");
          // }}
        />
      )}
      <main className='main'>
        <div className='container-fluid'>
          <div className='tab-import addproject'>
            <ul className='nav border-b nav-pills mb-3 justify-content-end mt-3' id='pills-tab' role='tablist'>
              <li className='nav-item new-project-first' role='presentation'>
                <button
                  className='tab_btn me-2 active'
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='true'
                >
                  New Project
                </button>
              </li>
              <li className='nav-item new-project-second' role='presentation'>
                <button
                  className='tab_btn'
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='false'
                >
                  Existing Project
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button type='button' className='roundedButton' style={{marginLeft:'5px', marginBottom:'5px'}} onClick={() => onActivateIntro()}>
                  <FontAwesomeIcon icon={faQuestion} />
                </button>
              </li>
            </ul>
            <div className='tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade show active'
                id='pills-home'
                role='tabpanel'
                aria-labelledby='pills-home-tab'
              >
                <div className='py-3'>
                  <form onSubmit={e => handleSubmit(e, 'Composer')}>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Name</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter project name'
                        name='projectname'
                        onChange={handleChange}
                        value={formData.projectname}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Project Description</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Description'
                        name='description'
                        onChange={handleChange}
                        value={formData.description}
                      />
                    </div>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Environment Type</span>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Environment'
                        name='environment'
                        onChange={handleChange}
                        value={formData.environment}
                      />
                      {/* <select
                      className="form-select"
                      id="inputGroupSelect02"
                      placeholder="Select environment type"
                      name="environment"
                      onChange={handleChange}
                      value={formData.environment}
                    >
                      <option value="1">Production</option>
                      <option value="2">Staging</option>
                      <option value="3">QA</option>
                    </select> */}
                    </div>
                    <div className='d-flex justify-content-end my-5'>
                      {isProjectAdd ? (
                        <>
                          <button onClick={() => navigate('/store')} className='orange_btn mx-1'>
                            Add Packages
                          </button>
                          <button onClick={() => navigate('/projects')} className='orange_btn mx-1'>
                            Go to Project
                          </button>
                        </>
                      ) : (
                        <button className='orange_btn mx-1'>Save Project</button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className='tab-pane row' id='pills-profile' role='tabpanel' aria-labelledby='pills-profile-tab'>
                <div className='col border-bottom ' style={{ borderColor: '#5f5f5f' }}>
                  <div className='page_title mt-4 mb-4'>
                    {/* <h1 className="m-0 text-white font20">Import Existing Project</h1> */}
                    <h1 className='m-0 text-white font20'>Select Option</h1>
                  </div>
                </div>
                <div className='py-3'>
                  <div>
                    <div className='row' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setType(e.target.value)}>
                      <div className='col-md-6 my-1'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            checked={type === 'wp-connection'}
                            type='radio'
                            name='option'
                            value='wp-connection'
                          />
                          <label className='form-check-label text-secondary' htmlFor='option1'>
                            Import existing site - WP connection
                          </label>
                        </div>
                        {/* <button className='orange_btn mx-1' type='submit' value='wp-connection' onClick={(e: any) => handleClick(e)}>WP Connection</button> */}
                      </div>
                      <div className='col-md-6 my-1'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            checked={type === 'composer'}
                            type='radio'
                            name='option'
                            value='composer'
                          />
                          <label className='form-check-label text-secondary' htmlFor='option1'>
                            Import existing site - composer.json - upload
                          </label>
                        </div>
                        {/* <button className='orange_btn mx-1' type='submit' value='ftp' onClick={(e: any) => handleClick(e)}>FTP Connection</button> */}
                      </div>
                      <div className='col-md-6 my-1'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            checked={type === 'ftp'}
                            type='radio'
                            name='option'
                            value='composer'
                          />
                          <label className='form-check-label text-secondary' htmlFor='option1'>
                            Import existing site - FTP
                          </label>
                        </div>
                        {/* <button className='orange_btn mx-1' type='submit' value='ssh' onClick={(e: any) => handleClick(e)}>SSH Connection</button> */}
                      </div>
                      <div className='col-md-6 my-1'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            checked={type === 'composer'}
                            type='radio'
                            name='option'
                            value='composer'
                          />
                          <label className='form-check-label text-secondary' htmlFor='option1'>
                            Import existing site - composer.json - repository
                          </label>
                        </div>
                        {/* <button className='orange_btn mx-1' type='submit' value='composer' onClick={(e: any) => handleClick(e)}>Composer.json Upload</button> */}
                      </div>
                      <div className='col-md-6 my-1'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            checked={type === 'ssh'}
                            type='radio'
                            name='option'
                            value='ssh'
                          />
                          <label className='form-check-label text-secondary' htmlFor='option1'>
                            Import existing site - SSH
                          </label>
                        </div>
                        {/* <button className='orange_btn mx-1' type='submit' value='composer' onClick={(e: any) => handleClick(e)}>Composer.json Repository</button> */}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end my-5'>
                    {type !== '' ? (
                      <button className='orange_btn mx-1' onClick={gotoAddproject}>
                        Configure project details
                      </button>
                    ) : (
                      <button disabled className='orange_btn mx-1'>
                        Configure project details
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default NewProject;
