/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { fetchData } from '../features/projectSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../features/hooks';
import Loader from './Loader';
import { faClose, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Modal } from 'react-bootstrap';
import backarrow from '../img/acc-arw.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAddEnviornmentQueueMutation, useGetQueueDataByUserQuery, useUpdateEnvQueueVersionMutation } from '../features/api';
import { fetchQueueData } from '../features/queuedata';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import CommonModal from './CommonModal';
import CommonAlert from './CommonAlert';
import { updateTrigger } from '../features/introArray';
import { Steps } from 'intro.js-react';
import { getColor } from '../utils/differentFunctions';
import { setDeployNumber, setPluginNumber, setQueueNumber } from '../features/notifyNumbers';
import { fetchDeployData } from '../features/getDeployData';
import { useNavigate } from 'react-router-dom';
import queueImg from '../img/queue.png'

const StoreQueue:React.FC = () => {
  const [projects, setProjects] = useState<Array<any>>([]);
  const [projectEnv, setProjectEnv] = useState<any>({});
  const [selectedEnv, setSelectedEnv] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [showPlugin, setShowPlugin] = useState<boolean>(false);
  const [projectCreate, setProjectCreate] = useState<any>({
    projectname: '',
    description: '',
  });
  const [createProjectError, setCreateProjectError] = useState<any>({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosePlugin = () => setShowPlugin(false);
  const handleShowPlugin = () => setShowPlugin(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [selectedVersions, setSelectedVersions] = useState<Record<string, string>>({});
  const storePlugins =
  localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];
  const [storePluginsState,setStorePluginsState] =useState<any>(storePlugins)
  const navigate = useNavigate();
  // console.log("storePlugins",storePlugins)
  const [addEnviornmentQueue] = useAddEnviornmentQueueMutation();
  const [storeQueue, setStoreQueue] = useState<Array<any>>([]);
  const queryString = new URLSearchParams(window.location.search);
  const [openAccordion, setOpenAccordion] = useState<any>(null);
  const {t, i18n} = useTranslation()
  const [isPopupOpen,setIsPopupOpen] = useState<boolean>(false)
  const [updateEnvQueueVersion,] = useUpdateEnvQueueVersionMutation()
  const [storeEnvPopupData,setStoreEnvPopupData] = useState<any>({})
  const [selectedPlugins, setSelectedPlugins] = useState(new Set());
  const [stepsEnabled, setStepsEnabled] = useState<any>(null);

useEffect(() => {
  if(storePlugins.length > 0 ){
    const defaultVersions: Record<string, string> = {};
    storePlugins.forEach((plugin: any) => {
      if(plugin.versions.length > 0){
        defaultVersions[plugin.slug] = plugin.versions[0].version;
      }
      else{
        defaultVersions[plugin.slug] = plugin.version;
      }

    });
    setSelectedVersions(defaultVersions);
  }
}, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData());
    dispatch(fetchQueueData())
    dispatch(fetchDeployData())
  }, [dispatch]);

  const { data: projectsData, isLoading } = useAppSelector((state: any) => state.projects);
  const { data: projectsData2 } = useAppSelector((state: any) => state.queueData);
  const introArray = useAppSelector((state) => state.introArray.arrayData)
  const {data:deployData} =useAppSelector((state) => state.deployData)
  console.log("introArray",introArray)
  const introTrigger = introArray.find((ik) => ik.page === "queue")?.trigger

  useEffect(() => {
    if (projectsData !== null) {
      setProjects(Object.prototype.hasOwnProperty.call(projectsData,"projects")?projectsData.projects:projectsData);
      // setProjects(projectsData.projects);
    }
  }, [projectsData]);

  useEffect(() => {
    if (projectsData2 !== null) {
      if (projectsData2.length > 0) {
        setStoreQueue(projectsData2);
      }
      else if(projectsData2.length === 0){
        setStoreQueue([])
      }
    }
  }, [projectsData2]);

  let initialSteps = 0;
  let QueueSteps = [
    {
      title: "welcome to store screen",
      // element: ".new-project-first",
      position: 'right',
      intro: "description",
    },
    {
      title: "select project",
      element: '.queue-first',
      position: 'left',
      intro: "description",
    },
    {
      title: "select environment",
      element: '.queue-second',
      position: 'left',
      intro: "description",
    },
    {
      title: "select plugin",
      element: '.queue-third',
      position: 'left',
      intro: "description",
    },
    {
      title: "Add it",
      element: '.queue-four',
      position: 'left',
      intro: "description",
    },
    {
      title: "Perform operation",
      element: '.queue-five',
      position: 'left',
      intro: "description",
    },
  ];
  useEffect(() => {
    console.log("introArray",introArray)
    if(introArray.length > 0){
      const getspecificdata = introArray.find((ik) => ik.page === "queue")
      if(getspecificdata !== undefined){
        if(getspecificdata.trigger === true){
          setStepsEnabled(true)
        }
      }
    }

  },[introTrigger])

  useEffect(() => {
    if(projectsData2){
      dispatch(setQueueNumber(projectsData2.length))
    }

  },[projectsData2,dispatch])

  useEffect(() => {
    if(deployData !== null){
      dispatch(setDeployNumber((deployData as any).length))
    }
  },[deployData])

  useEffect(() => {
    if (localStorage.getItem('introjs') === null) {
      const array = [];
      const homeScreen = { screen: 'queue', status: 'true' };
      array.push(homeScreen);
      localStorage.setItem('introjs', JSON.stringify(array!));
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'queue';
      });
      if (index < 0) {
        const homeScreen = { screen: 'queue', status: 'true' };
        array.push(homeScreen);
        setStepsEnabled(homeScreen.status === 'true' ? true : false);
        // manageQueryString(homeScreen.status)
        localStorage.setItem('introjs', JSON.stringify(array!));
      } else {
        // manageQueryString(array[index].status)
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    }
  }, []);

  const onIntroExit = (e: any): void => {
    if (e === -1) {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'queue';
      });
      if (index >= 0) {
        setStepsEnabled(array[index].status === 'true' ? true : false);
      }
    } else {
      const array = localStorage.getItem('introjs') !== null ? JSON.parse(localStorage.getItem('introjs')!) : [];
      const index = array.findIndex((i: any) => {
        return i.screen === 'queue';
      });
      if (index >= 0) {
        array[index].status = 'false';
        // manageQueryString(array[index].status)
        dispatch(updateTrigger({page: 'queue', trigger: false}))
        setStepsEnabled(array[index].status === 'false' ? false : true);
        localStorage.setItem('introjs', JSON.stringify(array!));
      }
    }
  };
  const onActivateIntro = () => {
    const getLocal = localStorage.getItem('introjs');
    if (getLocal !== null) {
      const getJson = JSON.parse(getLocal);
      const getIndex = getJson.findIndex((i: any) => {
        return i.screen === 'project-list-screen';
      });

      if (getIndex > -1) {
        getJson[getIndex].status = 'true';
        setStepsEnabled(true);
        localStorage.setItem('introjs', JSON.stringify(getJson));
      }
    }
  };

  const selectProjects = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filteredEnv = projects.find((i: any) => {
      return i.id === parseInt(event.target.value);
    });
    if (filteredEnv !== undefined) {
      setProjectEnv(filteredEnv);
    }
  };
  const selectEnv = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filteredEnv = projectEnv.meta.find((i: any) => {
      return i.meta.id === parseInt(event.target.value);
    });
    if (filteredEnv !== undefined) {
      setSelectedEnv(filteredEnv);
    }
  };
  const validatecreateProject = () => {
    let newError: any = {};
    if (!projectCreate.projectname) {
      newError.projectname = `${t('store-list.queue.error.Project-name-is-required.')}`;
    }
    if (!projectCreate.description) {
      newError.description = `${t('store-list.queue.error.Description-is-required.')}`;
    }
    setCreateProjectError(newError);
    return Object.keys(newError).length === 0;
  };

  useEffect(() => {
    dispatch(setPluginNumber(storePluginsState.length))
  },[storePluginsState])

  const onProjectCreate = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (validatecreateProject()) {
      const payload = {
        name: projectCreate.projectname,
        slug: projectCreate.projectname,
        description: projectCreate.description,
        flag: 'project_create',
        project_type: 0,
      };

      const data1: any = {
        id: 1,
        data: payload,
      };
      const formData = new FormData();
      formData.append('id', data1.id);

      for (const key in data1.data) {
        if (data1.data.hasOwnProperty(key)) {
          formData.append(key, data1.data[key]);
        }
      }
      formData.forEach((value, key) => {
        // console.log(key, value);
      });

      axios({
        url: `${process.env.REACT_APP_API_URL}/api/team/${data1.id}/project`,
        method: 'POST',
        data: formData,
        headers: {
          // Accept: "multipart/form-data",
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res1: any) => {
          if (Object.keys(res1).length > 0) {
            if (res1.data.hasOwnProperty('error')) {
              toast.error(`${res1.data.message}`);
            } else {
              toast.success(`${res1.data.message}`);
              dispatch(fetchData());
              setProjectCreate({
                projectname: '',
                description: '',
              });
              handleClose();
            }
          }
        })
        .catch((err1: any) => {
          console.log('err1', err1);
        });
    }
  };

  const cancelChange = (slug: string, env_id: number, flag: string) => {
    const payload = {
      slug: slug,
      project_env_id: env_id,
      flag: flag,
    };

    axios({
      url: `${process.env.REACT_APP_API_URL}/api/deleteQueueData`,
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res1: any) => {
      if (Object.keys(res1).length > 0) {
        if (res1.data.hasOwnProperty('error')) {
          toast.error(`${res1.data.message}`);
        } else {
          toast.success(`${res1.data.message}`);
          dispatch(fetchQueueData())
        }
      }
    })
    .catch((err1: any) => {
      console.log('err1', err1);
    });
  }

  const updateProject = async(e: any) => {
    const meta = JSON.parse(e.enviornment.meta);
    if (e.enviornment.meta !== null && meta.type === "composer") {
        const payload = {
          plugins: e.plugins,
          enviornment: e.enviornment,
          project: e.project,
          meta: meta
        };
        payload.plugins.map((plugin: any) => {
          const { slug, plugin_version, type } = plugin;
          const key = `${type}/${slug}`;

          if (payload.meta.composer.require[key]) {
            payload.meta.composer.require[key] = plugin_version;
          } else {
            payload.meta.composer.require[key] = plugin_version;
          }
        })

        var jsonString = JSON.stringify(payload.meta.composer);
        var blob = new Blob([jsonString], { type: 'application/json' });
        var file = new File([blob], `data-${e.enviornment.id}.json`, { type: 'application/json' });

        const payload1 ={
          ...payload,
          composer_file:file
        }

        console.log("payload", payload1);
        const formData = new FormData()
        formData.append('plugins',JSON.stringify(payload1.plugins))
        formData.append('enviornment',JSON.stringify(payload1.enviornment))
        formData.append('project',JSON.stringify(payload1.project))
        formData.append('meta',JSON.stringify(payload1.meta))
        formData.append('composer_file',payload1.composer_file)
        formData.append('type',meta.type)

        axios({
          url: `${process.env.REACT_APP_API_URL}/api/updateStoreQueue`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res1: any) => {
          console.log("res1",res1)
          if (Object.keys(res1).length > 0) {
            if (res1.data.hasOwnProperty('error')) {
              toast.error(`${res1.data.message}`);
            } else {
              toast.success(`Selected packages added to the ${payload1.project.project_name} project in ${payload1.enviornment.name} environment`)
              // toast.success(`${res1.data.message}`);
              dispatch(fetchQueueData())
              setSelectedCheckboxes([])
              dispatch(fetchDeployData())
            }
          }
        })
        .catch((err1: any) => {
          console.log('err1', err1);
        });
      }
    else if(e.enviornment.meta !== null && meta.type === "wp") {
      try{
        const encodedCredentials = Buffer.from(`${meta?.username}:${atob(meta?.app_password)}`).toString('base64');
        const headers = {
            Authorization: `Basic ${encodedCredentials}`,
          };
        const plugs = await axios.get(`${e.enviornment.siteurl}/wp-json/wp/v2/plugins`,{ headers })
        if(plugs){
          const mergePlugs = e.plugins.map((ik:any) => {
            const merger = plugs.data.find((i:any) => {return i.plugin.split('/')[0] === ik.slug})
            if(ik.action.includes('sync-')){
              return {
                ...ik,
                plug_data: merger !== undefined?merger:{}
              }
            } else {
              return {
                ...ik,
                plug_data: {}
              }
            }
          })
          const payload = {
            plugins: mergePlugs,
            enviornment: e.enviornment,
            project: e.project,
            meta: meta
          };
          console.log("payload",payload)
          const formData = new FormData()
            formData.append('plugins',JSON.stringify(payload.plugins))
            formData.append('enviornment',JSON.stringify(payload.enviornment))
            formData.append('project',JSON.stringify(payload.project))
            formData.append('meta',JSON.stringify(payload.meta))
            // formData.append('composer_file','')
            formData.append('type',meta.type)

            axios({
              url: `${process.env.REACT_APP_API_URL}/api/updateStoreQueue`,
              method: 'POST',
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            })
            .then((res1: any) => {
              console.log("res1",res1)
              if (Object.keys(res1).length > 0) {
                if (res1.data.hasOwnProperty('error')) {
                  toast.error(`${res1.data.message}`);
                } else {
                  toast.success(`Selected packages added to the ${payload.project.project_name} project in ${payload.enviornment.name} environment`)
                  // toast.success(`${res1.data.message}`);
                  dispatch(fetchQueueData())
                  setSelectedCheckboxes([])
                  dispatch(fetchDeployData())
                }
              }
            })
            .catch((err1: any) => {
              console.log('err1', err1);
            });
        }

      } catch (err1){
        toast.error(`SomeThing Went Wrong`)
      }


      // console.log("e",e)
      // const meta = JSON.parse(e.enviornment.meta);
      // const encodedCredentials = Buffer.from(`${meta?.username}:${atob(meta?.app_password)}`).toString('base64');
      // const headers = {
      //   Authorization: `Basic ${encodedCredentials}`,
      // };
      // for(let i = 0; i < e.plugins.length; i++) {
      //   const Result = await axios.get(`${e?.enviornment.siteurl}/wp-json/wp/v2/plugins`, { headers });
      //   const updatedData = Result.data.find((item:any) => item.plugin.split("/")[0] === e.plugins[i].slug);
      //   const updatedData1 = e.plugins.find((item:any) => {return !Result.data.some((i:any) => {return i.plugin === `${item.type}/${item.slug}`})});
      //   console.log("updatedData1",updatedData1)
      //   if(updatedData1!== undefined && Object.keys(updatedData1).length > 0){
      //      const abc = await axios({
      //       url:`${e?.enviornment.siteurl}/wp-json/wp/v2/plugins`,
      //       method:"post",
      //       headers:headers,
      //       data:{
      //         slug:updatedData1.slug,
      //         action: "install"
      //       }
      //      })
      //      console.log("abc",abc)
      //   }
      // //   if(updatedData && updatedData !== 'undefined') {
      // //     if(updatedData.status === "active") {
      // //       let data = {
      // //         slug: updatedData.plugin.split('/')[1],
      // //         status:'inactive',
      // //       };
      // //       await axios.put(`${e?.enviornment.siteurl}wp-json/wp/v2/plugins/${updatedData.plugin}`, data, { headers });
      // //     }
      // //     else {
      // //       const deleteData = await axios.delete(`${e?.enviornment.siteurl}/wp-json/wp/v2/plugins/${updatedData.plugin}`, { headers });
      // //       if(deleteData.data.deleted) {
      // //       let data = {
      // //         slug: e.plugins[i].slug,
      // //         // version: e.plugins[i].plugin_version,
      // //         status: 'active',
      // //       };
      // //       const Result2 = await axios.post(`${e?.enviornment.siteurl}/wp-json/wp/v2/plugins`, data, { headers });
      // //       const payload = {
      // //         plugins: JSON.stringify(Result2.data),
      // //       }
      // //       const formData = new FormData()
      // //       formData.append('plugins',JSON.stringify(payload.plugins))
      // //       formData.append('type',meta.type)

      // //       // if(Result2.data) {
      // //       //   axios({
      // //       //     url: `${process.env.REACT_APP_API_URL}/api/updateStoreQueue`,
      // //       //     method: 'POST',
      // //       //     data: formData,
      // //       //     headers: {
      // //       //       'Content-Type': 'multipart/form-data',
      // //       //       Authorization: `Bearer ${localStorage.getItem('token')}`,
      // //       //     },
      // //       //   })
      // //       //   .then((res1: any) => {
      // //       //     console.log("res1",res1)
      // //       //     if (Object.keys(res1).length > 0) {
      // //       //       if (res1.data.hasOwnProperty('error')) {
      // //       //         toast.error(`${res1.data.message}`);
      // //       //       } else {
      // //       //         toast.success(`${res1.data.message}`);
      // //       //         dispatch(fetchQueueData())
      // //       //         setSelectedCheckboxes([])
      // //       //       }
      // //       //     }
      // //       //   })
      // //       //   .catch((err1: any) => {
      // //       //     console.log('err1', err1);
      // //       //   });
      // //       // }
      // //     }
      // //   }
      // // }
      // // else {
      // //   console.log('do nothing')
      // // }
      // }
    }
  };

  const clearQueue = (flag: string) => {
    const payload = {
      flag: flag,
    };

    axios({
      url: `${process.env.REACT_APP_API_URL}/api/deleteQueueData`,
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res1: any) => {
      if (Object.keys(res1).length > 0) {
        if (res1.data.hasOwnProperty('error')) {
          toast.error(`${res1.data.message}`);
        } else {
          toast.success(`${res1.data.message}`);
          dispatch(fetchQueueData())
          setStoreQueue([])
          setSelectedCheckboxes([])
        }
      }
    })
    .catch((err1: any) => {
      console.log('err1', err1);
    });
  }

  const updateAll = async() => {
    if (storeQueue && storeQueue.length > 0) {
      const formData = new FormData();
      const composerData = storeQueue.filter((e: any) => {
        // const meta = JSON.parse(e.enviornment.meta);
          return e.enviornment.meta !== null
        });
        const arr:any = []
        for (const [index, e] of composerData.entries()){
          const meta = JSON.parse(e.enviornment.meta);
          if(meta.type === "composer"){
            const payload = {
              plugins: e.plugins,
              enviornment: e.enviornment,
              project: e.project,
              // meta: meta
            };
            payload.plugins.forEach((plugin: any) => {
              const { slug, plugin_version, type } = plugin;
              const key = `${type}/${slug}`;

              if (meta.composer.require[key]) {
                meta.composer.require[key] = plugin_version;
              } else {
                meta.composer.require[key] = plugin_version;
              }
            })
            var jsonString = JSON.stringify(meta.composer);
            var blob = new Blob([jsonString], { type: 'application/json' });
            var file = new File([blob], `data-${e.enviornment.id}.json`, { type: 'application/json' });

            formData.append('data'+index, JSON.stringify(payload));
            formData.append('composer_file'+index, file);
            formData.append('meta'+index, JSON.stringify(meta));
          } else if(meta.type === "wp") {
            try{
              const encodedCredentials = Buffer.from(`${meta?.username}:${atob(meta?.app_password)}`).toString('base64');
              const headers = {
                  Authorization: `Basic ${encodedCredentials}`,
                };

                const plugs = await axios.get(`${e.enviornment.siteurl}/wp-json/wp/v2/plugins`,{ headers })
                console.log("plugs",plugs)
                if(plugs){
                const mergePlugs = e.plugins.map((ik:any) => {
                  const merger = plugs.data.find((i:any) => {return i.plugin.split('/')[0] === ik.slug})
                  if(ik.action.includes('sync-')){
                    return {
                      ...ik,
                      plug_data: merger !== undefined?merger:{}
                    }
                  } else {
                    return {
                      ...ik,
                      plug_data: {}
                    }
                  }

                })
              const payload = {
                plugins: mergePlugs,
                enviornment: e.enviornment,
                project: e.project,
                // meta: meta
              };
              arr.push(mergePlugs)
              console.log("pyloads",payload)
              formData.append('data'+index, JSON.stringify(payload));
              formData.append('composer_file'+index, '');
              formData.append('meta'+index, JSON.stringify(meta));
              // formData.forEach((key, value) => {
              //   console.log("ab",key, value)
              // })
            }
            }catch(err1){
              console.log("err1",err1)

            }

          }


            // return {
            //   ...payload,
            //   composer_file:file
            // }
        }


        formData.append('data_length', await(composerData as any).length)

          formData.forEach((key, value) => {
            console.log("ab",key, value)
          })


      axios({
        url: `${process.env.REACT_APP_API_URL}/api/updateAllQueue`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res1: any) => {
        if (Object.keys(res1).length > 0) {
          if (res1.data.hasOwnProperty('error')) {
            toast.error(`${res1.data.message}`);
          } else {
            toast.success(`Selected packages added to the ${composerData.map((e: any) =>  e.project.project_name)} environment`)
            // toast.success(`${res1.data.message}`);
            dispatch(fetchQueueData())
            dispatch(fetchDeployData())
          }
        }
      })
      .catch((err1: any) => {
        console.log('err1', err1);
      });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (value === 'selectAll') {
      const allSlugs = storePluginsState.map((x:any) => x.slug);
      if (checked) {
        setSelectedCheckboxes(allSlugs);
      } else {
        setSelectedCheckboxes([]);
      }
    } else {
      if (checked) {
        setSelectedCheckboxes([...selectedCheckboxes, value]);
      } else {
        setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== value));
      }
    }
  };

  const isAllSelected = () => {
    return selectedCheckboxes.length === storePluginsState.length;
  };

  const isSelectAllChecked = () => {
    return selectedCheckboxes.length > 0 && selectedCheckboxes.length === storePluginsState.length;
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, packageName: string) => {
    setSelectedVersions({ ...selectedVersions, [packageName]: event.target.value });
  };

  const handleSelectButtonClick = () => {
    console.log("selectedEnv",selectedEnv)
    const meta1 = JSON.parse(selectedEnv.meta.meta)
    const selectedData = selectedCheckboxes.map(slug => ({ slug, version: selectedVersions[slug] }));
    const selectedPlugins = selectedData.map((i) => {
      const getPlug = storePlugins.find((plugin: any) => plugin.slug === i.slug);
      if(getPlug !== undefined){
        return {
          ...i,
          plugin_details:getPlug
        }
      }else{
        return {
          ...i,
          plugin_details:{}
        }
      }
    });
    console.log("selectedPlugins",selectedPlugins,projectEnv, selectedEnv)
    if(selectedEnv.meta.meta !==null){
      const selected = selectedPlugins.map((i:any) => {
        if(meta1.type === "wp"){
          return {
            plugin_slug: i.slug,
            plugin_version: i.version,
            env_id: parseInt(selectedEnv.meta.id),
            project_id: parseInt(projectEnv.id),
            flag: 'add_from_store',
            version: i.version,
            action:"add"
          };
        } else {
          return {
            plugin_slug: i.slug,
            plugin_version: i.version,
            env_id: parseInt(selectedEnv.meta.id),
            project_id: parseInt(projectEnv.id),
            flag: 'add_from_store',
            version: i.version,
          };
        }

      })
      console.log("selected",selected)
      if(selected.length > 0 ){
        addEnviornmentQueue({data:selected}).then((ik: any) => {
          if (ik.error) {
            toast.error(ik.error.message.message);
          } else {
            toast.success(`Selected packages added to the ${projectEnv.name} environment`);
            setShowPlugin(false);
            dispatch(fetchQueueData())
            setSelectedCheckboxes([])
            // setSelectPluginData([]);
          }
        });
      } else {
        toast.info('Please select plugins')
      }
    } else {
      toast.info('environment is not configured')
    }

  };

  function isValidJSONString(str: string, ik: any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      console.log('e', e, ik);
      // toast.error(`there is json parse error in ${ik.meta.name} in ${item.name}`)
      return false;
    }
  }
  const toggleAccordion = (index: any): void => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };
  console.log('projects',projects)

  const removeLocalPlugins =(x:any):void => {
    console.log("x data", x)
    const filterData = storePluginsState.filter((ik:any) => {return ik.slug !== x.slug})
    setStorePluginsState(filterData)
    localStorage.setItem('storequeue',JSON.stringify(filterData))
  }
  const removeAllLocalPlugins =():void => {

    setStorePluginsState([])
    localStorage.setItem('storequeue',JSON.stringify([]))
  }
  const changePluginVersionforPlugin = (item:any,env:any,e:React.ChangeEvent<HTMLSelectElement>):void => {
    // if(window.confirm('Are you sure want to change plugin version for ' +item.slug+" in "+env.name)){
    console.log('item',item,env,e.target.value)
    // setStoreEnvPopupData({
    //   item:item,
    //   env:env,
    //   e:e
    // })
    // setIsPopupOpen(true)

    const data1 = {
      project_env_id:env.id,
      slug:item.slug,
      version:item.plugin_version,
      changeVersion:e.target.value
    }
    updateEnvQueueVersion(data1).then((res1:any) => {
      if(res1){


        if(Object.prototype.hasOwnProperty.call(res1.data,"error")){

          toast.error('something went wrong')
          dispatch(fetchQueueData())
        } else {

          toast.success('Version changed for plugin '+ item.slug +" in " +env.name)
          dispatch(fetchQueueData())
        }

      }
    }).catch((err1) => {
      toast.error('something went wrong')
    })
  // }
  }
  // const clickPopupFunc = ():void => {
  //   const data1 = {
  //     project_env_id:storeEnvPopupData.env.id,
  //     slug:storeEnvPopupData.item.slug,
  //     version:storeEnvPopupData.item.version,
  //     changeVersion:storeEnvPopupData.e.target.value
  //   }
  //   updateEnvQueueVersion(data1).then((res1) => {
  //     if(res1){
  //       toast.success('Version changed for plugin '+ storeEnvPopupData.item.slug +" in " +storeEnvPopupData.env.name)
  //       dispatch(fetchQueueData())
  //       setIsPopupOpen(false)
  //       setStoreEnvPopupData({})
  //     }
  //   }).catch((err1) => {
  //     setIsPopupOpen(false)
  //     setStoreEnvPopupData({})
  //   })
  // }

  if (projectsData === null) {
    return <Loader />;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
     <Steps
          enabled={stepsEnabled}
          steps={QueueSteps}
          // onExit={() => (stepsEnabled === false ? false : stepsEnabled)}
          onExit={e => onIntroExit(e)}
          initialStep={initialSteps}
          options={{
            showProgress: true,
            // showStepNumbers: true,
          }}
          onAfterChange={() => {
            const tooltip = document.querySelector('.introjs-tooltip');
            if (tooltip) {
              const footer = document.createElement('div');
              footer.classList.add('customTooltipFooter');
              footer.innerHTML = `
                <span style="font-size:small; color:red;">NOT HELPFUL?&nbsp;
                  <a href="https://your-documentation-link.com" style="font-size:small; color:red;" target="_blank" rel="noopener noreferrer">
                    Check out the full documentation.
                  </a>
                </span>

              `;
              // Append the footer only if it's not already appended
              if (!tooltip.querySelector('.customTooltipFooter')) {
                tooltip.appendChild(footer);
              }
            }
          }}
          // onBeforeExit={() => {
          //   let data =
          //     stepsEnabled !== null &&
          //     window.confirm("Don't want to show this again then press Ok");
          //   stepsEnabled !== null &&
          //   data &&
          //   localStorage.setItem("IntroToken", "false");
          // }}
        />
    <main className='main'>
    {/* <div style={{marginLeft:'60px', marginBottom:'40px'}}>
      <button type='button' className='cblue_btn back_btn' onClick={goBack}>
                            <span className='back_btn_icon' style={{marginRight:'5px'}}>
                              <img className='img-fluid' src={backarrow} alt='back-btn' />
                            </span>
                            <span>Back</span>
                        </button>
      </div> */}
      <div className='main_container'>
        <div className='queue_main row'>
          <div className='col-6'>
            <div className='inner_header'>
              <div className='field-group queue-first'>
                <span className='input_title_text'>Project</span>
                <select
                  className='environment-select form-select'
                  style={{ backgroundColor: '#202020', color: 'white' }}
                  onChange={e => selectProjects(e)}
                >
                  <option value='' >
                {t('store-list.queue.Select-Project')}
                </option>
                  {typeof projects !== 'undefined' && projects.length > 0 && projects.map((ik: any) => {
                    return <option value={ik.id}>{ik.slug}</option>;
                })}
                  {/* <option value=''>Select Project</option>
                  <option value='43'>test12</option>
                  <option value='44'>hello</option>
                  <option value='46'>sagar123</option>
                  <option value='45'>demo</option>
                  <option value='52'>hello123</option> */}
                </select>
              </div>
              <div className='field-group queue-second'>
                <span className='input_title_text'>Environment</span>
                <select
                  className='environment-select form-select'
                  style={{ backgroundColor: '#202020', color: 'white' }}
                  onChange={e => selectEnv(e)}
                >
                   <option value='' >
                    {t('store-list.queue.Select-Environment')}
                    </option>
                {Object.keys(projectEnv).length > 0 ? (
                Object.prototype.hasOwnProperty.call(projectEnv, 'meta') ? (
                  projectEnv.meta.map((ik: any) => {
                    return <option value={ik.meta.id}>{ik.meta.name}</option>;
                  })

                ) : null
              ) : null}
              </select>
              </div>
              <button
                className='cblue_btn d-flex align-items-center queue-four'
                style={{ marginRight: '25px', height: '35px', padding: '0px 40px' }}
                onClick={handleSelectButtonClick}
                disabled={Object.keys(selectedEnv).length > 0 ? false:true}
              >
                {t('store-list.queue.buttons.Add')}
              </button>
            </div>
            <div className='queue_table_wrap'>
            <p style={Object.keys(selectedEnv).length===0?{color:"red"}:{}}>{Object.keys(selectedEnv).length === 0 ? 'please select project and environment':null}</p>
            <div className='table-responsive'>
      <table className='table queue_table queue-third'>
        <thead>
          <tr>
            <th>
              <div className='d-flex'>
                <div className='filter'>
                  <div className='ck_main lh-1'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='selectAll'
                      checked={isSelectAllChecked()}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
                <div>
                  <span
                    className='d-block'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='WooCommerce'
                    style={{ lineHeight: '1' }}
                  >
                    Select All
                  </span>
                </div>
              </div>
            </th>
            <th>{t('store-list.queue.Type')}</th>
            <th>{t('store-list.queue.modals.Version')}</th>
            <th>
              <button type="button" className='remove_btn' onClick={removeAllLocalPlugins}>
                Remove All
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {storePluginsState.map((x:any) => (
            <tr key={x.slug}>
              <td>
                <div className='d-flex'>
                  <div className='filter'>
                    <div className='ck_main lh-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id={x.slug}
                        value={x.slug}
                        checked={selectedCheckboxes.includes(x.slug)}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                  <div>
                    <span
                      className='text-white d-block'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title={x.name !== "" ? x.name : x.slug}
                      style={{ lineHeight: '1' }}
                    >
                      {x.name !== "" ? x.name.substring(0, 15) + " ..." : x.slug}
                    </span>
                  </div>
                </div>
              </td>
              <td>Plugins</td>
              <td>
                <div className='inner_table_select'>
                  {x.versions.length > 0 ? (
                    <select className='form-select select-dropdown' onChange={(e) => handleSelectChange(e, x.slug)}>
                      {x.versions.map((i:any) => (
                        <option key={i.version} value={i.version}>
                          {i.version}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div>{x.version}</div>
                  )}
                </div>
              </td>
              <td>
                <button type="button" className='remove_btn' onClick={() => removeLocalPlugins(x)}>
                <FontAwesomeIcon icon={faTrash}  />
                </button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
            </div>
          </div>

          <div className='col-6 queue-five'>
          {typeof storeQueue !== 'undefined' && storeQueue.length > 0 ?
            <div className='update_container'>

                  <div className='update_container_header'>
                <span className='title_text'>Project</span>
                <div className='d-flex'>
                  <button className='orange_btn mx-1' type='button' onClick={() => clearQueue('clearAll')}>
                    Remove All
                  </button>
                  <button
                    className='orange_btn d-flex align-items-center'
                    onClick={() => updateAll()}
                  >
                    Update All
                  </button>
                </div>
              </div>
              {typeof storeQueue !== 'undefined' && (
                  <>
                    {storeQueue.length > 0 ? (
                      storeQueue.filter((i: any) => {
                        if (window.location.search.length > 0) {
                          if (
                            i.project.project_id === parseInt(queryString.get('project') as any) &&
                            i.enviornment.id === parseInt(queryString.get('environment') as any)
                          ) {
                            return i;
                          } else {
                            return null;
                          }
                        } else {
                          return i;
                        }
                      }).map((k: any, index: number) => {
                        const isAccordionOpen = openAccordion === index;
                        const isValid = isValidJSONString(k.enviornment.meta, k)
                        if(isValid){

                          return(
                            <div className='project_container'>
                <div className='inner_project_container'>
                  <div className='inner_project_container_header'>
                    <span className='title_text'>{k.project.project_name}</span>
                    <button
                      className='cblue_btn d-flex align-items-center'
                      style={{ height: '35px', padding: '0px 27.5px' }}
                      onClick={() => updateProject(k)}
                    >
                      {t('store-list.queue.buttons.Update-Project')}
                    </button>
                  </div>

                  <div className='queue_table_group'>

                    <div className='table-responsive'>
                      <table className='table queue_table'>
                        <thead>
                          <tr>
                            <th>{k.enviornment.name}</th>
                            <th>{t('store-list.queue.Action')}</th>
                            <th>{t('store-list.queue.New-version')}</th>
                            <th>{t('store-list.queue.Old-version')}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {k.plugins.map((x: any, index1: number) => {
                          return(
                            <tr>
                            <td><span title={(x.name !== "") ? x.name: x.slug}>{(x.name !== "") ? x.name.substring(0,15) + " ...": x.slug}</span></td>
                            <td><span style={{color:getColor(x.action)}}>{x.action}</span></td>
                            {/* <td>v{x.plugin_version}</td> */}
                              <td>
                                <div className='inner_table_select'>
                                <select
                                  className='form-select select-dropdown'
                                  // value={x.plugin_version}
                                  onChange={(e) => changePluginVersionforPlugin(x,k.enviornment,e)}
                                >
                                  <option>{x.plugin_version}</option>
                                  {x.Allversion
                                   .filter((version:any) => version.version !== x.plugin_version)
                                    .slice()
                                    .reverse()
                                    .map((version: any, index: any) => {
                                      return (
                                        <option key={index} value={version.version}>
                                          {version.version}
                                        </option>
                                      );
                                    })}
                                </select>
                                </div>
                              </td>
                            <td>{x.version !== null ? `v${x.version}`: ""} </td>

                            <td>
                              <button type="button" className='remove_btn' onClick={() => cancelChange(x.slug, k.enviornment.id, 'clearOne')}>
                                <FontAwesomeIcon icon={faTrash} />
                              </button>

                            </td>
                          </tr>
                          )
                        })}


                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>


              </div>
                          )
                        }
                      })):null}</>)}

            </div> :
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src={queueImg} style={{width:'auto', height:'350px', marginTop:'70px'}}></img>
              <h3 style={{color:'#6db1bc'}}>Your Queue is empty, Please select package and add it to the project environment</h3>
            </div>
            }
          </div>
        </div>
      </div>
      {/* <CommonAlert
          onHide={setIsPopupOpen}
          show={isPopupOpen}
          message={'Are you sure you want to change version'}
          clickFunc={clickPopupFunc}
        /> */}
    </main>

    {/* <main className='main main--slide'>
      <div className='container-fluid'>
        <div className='storequeue_search_btn mb-4'>
          {typeof projects !== 'undefined' && projects.length > 0 && (
            <div className='d-flex justify-content-end'>

            <div>

              <button className='orange_btn mx-1' type='button' onClick={() => handleShowPlugin()}>
              {t('store-list.queue.buttons.Select-Package-Version')}
              </button>
            </div>
            </div>
          )}
        </div>
      </div>
      <div className='store-queue'>
        <div className='container-fluid'>
          <div className='table-responsive'>
            <table className='table'>
              <tbody className='projects-second'>
                {typeof storeQueue !== 'undefined' && (
                  <>
                    {storeQueue.length > 0 ? (
                      storeQueue.filter((i: any) => {
                        if (window.location.search.length > 0) {
                          if (
                            i.project.project_id === parseInt(queryString.get('project') as any) &&
                            i.enviornment.id === parseInt(queryString.get('environment') as any)
                          ) {
                            return i;
                          } else {
                            return null;
                          }
                        } else {
                          return i;
                        }
                      }).map((k: any, index: number) => {
                        const isAccordionOpen = openAccordion === index;
                        const isValid = isValidJSONString(k.enviornment.meta, k);
                        if (isValid) {
                          return (
                            <tr>
                              <td colSpan={6} className='p-0 accordion_row border-0'>
                                <div className='accordion' id={`accordionExample${index}`}>
                                  <div className='accordion-item' style={{ background: '#101010' }}>
                                    <table className="table">
                                      <thead>
                                        <tr className='accordion-header'>
                                          <th><div className='project_name'>{k.project.project_name}</div></th>
                                          <th>{t('store-list.queue.Item')}</th>
                                          <th>{t('store-list.queue.Type')}</th>
                                          <th>{t('store-list.queue.Action')}</th>
                                          <th>{t('store-list.queue.Old-version')}</th>
                                          <th>{t('store-list.queue.New-version')}</th>
                                          <th>
                                            <div className='d-flex justify-content-end'>
                                              <div
                                                className='accordion_button_arrow'
                                                onClick={() => toggleAccordion(index)}
                                              >
                                                <div
                                                  className={`accordion_arrow ${isAccordionOpen ? 'open' : 'collapsed'}`}
                                                  onClick={() => toggleAccordion(index)}
                                                >
                                                </div>
                                              </div>
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody id={`collapseOne${index}`}
                                        className={`accordion-collapse collapse ${isAccordionOpen ? 'show' : ''}`}
                                        aria-labelledby={`headingOne${index}`}
                                        data-bs-parent={`#accordionExample${index}`}>
                                        {k.plugins.map((x: any, index1: number) => {
                                          return (
                                            <tr>
                                              <td>{index1 === 0 ?<span className="project_stage_title">{k.enviornment.name}</span>:null}</td>
                                              <td>{x.slug}</td>
                                              <td>{x.type}</td>
                                              <td>{x.action}</td>
                                              <td>{x.version !== null ? x.version: ""}</td>
                                              <td>{x.plugin_version}</td>
                                              <td>
                                                <div className='d-flex align-items-center g-1 justify-content-end'>
                                                  <button
                                                    className='red_btn d-flex align-items-center'
                                                    onClick={() => cancelChange(x.slug, k.enviornment.id, 'clearOne')}
                                                  >
                                                    <span className='d-inline-block ms-1'>
                                                    {t('store-list.queue.buttons.Cancel-Change')}
                                                    </span>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        })}
                                        <tr>
                                          <td colSpan={7}>
                                            <div className='table_last_btn d-flex justify-content-end'>
                                              <button
                                                className='cblue_btn d-flex align-items-center'
                                                onClick={() => updateProject(k)}
                                              >
                                                <span className='d-inline-block ms-1'>{t('store-list.queue.buttons.Update-Project')}</span>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                          }
                        })
                      ) : (
                        <p className='text-white'>{t('store-list.queue.No-Data-found')}</p>
                      )}
                    </>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-end">
        <button className='orange_btn mx-1' type='button' onClick={() => clearQueue('clearAll')}>
        {t('store-list.queue.buttons.Clear-Queue')}
        </button>
        <button className='cblue_btn mx-1' type='button' onClick={() => updateAll()}>
        {t('store-list.queue.buttons.Update-All')}
        </button>
      </div>
      <CommonModal className='composer-model' show={show} onHide={handleClose} animation={false} size='lg'>
        <CommonModal.Header>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <FontAwesomeIcon icon={faClose} onClick={handleClose} style={{ color: 'white' }} size='lg' />
          </div>
        </CommonModal.Header>
        <CommonModal.Body>

          <form className='addproject' onSubmit={e => onProjectCreate(e)}>
            <div className='input-group mb-3'>
              <span className='input-group-text'>{t('store-list.queue.forms.Project-Name')}</span>
              <input
                type='text'
                className='form-control'
                placeholder='Enter project name'
                name='projectname'
                value={projectCreate.projectname}
                onChange={e => {
                  setProjectCreate({
                    ...projectCreate,
                    projectname: e.target.value,
                  });
                  if (createProjectError.projectname) {
                    setCreateProjectError((prevErrors: any) => ({ ...prevErrors, projectname: null }));
                  }
                }}
              />
            </div>
            {createProjectError.projectname && <div style={{ color: 'red', fontSize: '14px' }}>{createProjectError.projectname}</div>}
            <div className='input-group mb-3'>
              <span className='input-group-text'>{t('store-list.queue.forms.Project Description')}</span>
              <input
                type='text'
                className='form-control'
                placeholder='Description'
                name='description'
                value={projectCreate.description}
                onChange={e => {
                  setProjectCreate({
                    ...projectCreate,
                    description: e.target.value,
                  });
                  if (createProjectError.description) {
                    setCreateProjectError((prevErrors: any) => ({ ...prevErrors, description: null }));
                  }
                }}
              />
            </div>
            {createProjectError.description && <div style={{ color: 'red', fontSize: '14px' }}>{createProjectError.description}</div>}

            <div className='d-flex justify-content-end my-5'>
              <button className='orange_btn mx-1'>{t('store-list.queue.buttons.Save-Project')}</button>
            </div>
          </form>
        </CommonModal.Body>

      </CommonModal>
      <CommonModal className='composer-model' show={showPlugin} onHide={handleClosePlugin} animation={false} size='lg'>
        <CommonModal.Header>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <FontAwesomeIcon icon={faClose} onClick={handleClosePlugin} style={{ color: 'white' }} size='lg' />
          </div>
         <div style= {{display:'flex'}}>
         <select className='environment-select form-select' onChange={e => selectProjects(e)} style={{marginRight:'20px'}}>
                <option value='' >
                {t('store-list.queue.Select-Project')}
                </option>
                {typeof projects !== 'undefined' && projects.length > 0 && projects.map((ik: any) => {
                  return <option value={ik.id}>{ik.slug}</option>;
                })}
              </select>
              {Object.keys(projectEnv).length > 0 ? (
                Object.prototype.hasOwnProperty.call(projectEnv, 'meta') ? (
                  <select className='environment-select form-select w-auto' onChange={e => selectEnv(e)} style={{marginLeft:'20px'}}>
                    <option value='' >
                    {t('store-list.queue.Select-Environment')}
                    </option>
                    {projectEnv.meta.map((ik: any) => {
                      return <option value={ik.meta.id}>{ik.meta.name}</option>;
                    })}
                  </select>
                ) : null
              ) : null}
         </div>
        </CommonModal.Header>
        <CommonModal.Body>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{ paddingTop: '15px', paddingBottom: '15px' }}>{t('store-list.queue.modals.Package-Name')}</th>
                  <th style={{ paddingTop: '15px', paddingBottom: '15px' }}>{t('store-list.queue.modals.Version')}</th>
                </tr>
              </thead>
              <tbody>
                {storePlugins.map((x: any) => {
                  return (
                    <tr>
                      <td>
                        <div className='d-flex'>
                          <div className='filter'>
                            <div className='ck_main'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value={x.slug}
                                onChange={handleCheckboxChange}
                              />
                            </div>
                          </div>
                          <div className='align-items-center plg_area mx-2'>
                            <div className='d-block mb-2' style={{ lineHeight: '1' }}>
                              {x.name ? x.name : x.slug}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                    {x.versions.length > 0 ?
                      <select className='environment-select form-select' onChange={(e) => handleSelectChange(e, x.slug)}>
                        {x.versions.map((i:any) => {
                          return <option key={i.version} value={i.version}>{i.version}</option>
                        })}
                      </select>
                    :  <div>{x.version}</div>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <p style={Object.keys(selectedEnv).length===0?{color:"red"}:{}}>{Object.keys(selectedEnv).length === 0 ? 'please select project and environment':null}</p>
              <button className='orange_btn' disabled={Object.keys(selectedEnv).length > 0 ? false:true} onClick={handleSelectButtonClick}>
              {t('store-list.queue.buttons.Add')}
              </button>
            </table>
          </div>
        </CommonModal.Body>
      </CommonModal>
    </main> */}
    </>
  );
}

export default StoreQueue;
