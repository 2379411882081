/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './sass/style.scss';
import Home from './components/Home';
import ProjectsList from './components/ProjectsList';
import StoreList from './components/StoreList';
import NotFound from './components/NotFound';
import './App.css'

import { useUpdateOtpPreferenceMutation, useUserSessionQuery } from './features/api';
import { setUserPlugins} from './features/userplugins';
import ProjectDetails from './components/ProjectDetails';
import Auth from './components/Auth';
import PrivateRoutes from './utils/PrivateRoutes';
import CommonLayout from './components/CommonLayout';
import AddProject from './components/AddProject';
import ComposerView from './components/ComposerView';
import ReviewQueue from './components/ReviewQueue';
import EditProject from './components/EditProject';
import Toast from './components/ToastContainer';
import Billing from './components/Billing';
import NewProject from './components/NewProject';
import Suports from './components/Suports';
import AuthOtp from './components/AuthOtp';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
// import 'intro.js/introjs.css';
// import { Steps } from 'intro.js-react';
// import { FusePressLogo } from './utils/extras';
import UserPrivatePackages from './components/UserPrivatePackages';
import SetPassword from './components/SetPassword';
import UserPackages from './components/UserPackages';
import ReviewUserPackage from './components/ReviewUserPackage';
import RejectedUserPackage from './components/RejectedUserPackage';
import UserAddPackage from './components/UserAddPackage';
import Documentation from './components/Documentation';
import PluginDetails from './components/PluginDetails';
import StoreQueue from './components/StoreQueue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleXmark, faSmile, faSquareCaretLeft } from '@fortawesome/free-solid-svg-icons';
import UserProfile from './components/UserProfile';
import { useTranslation } from 'react-i18next';
import { fetchUserDetails } from './features/userDetailsSlice';
import { useDispatch} from 'react-redux';
import { useAppSelector } from './features/hooks';
import Loader from './components/Loader';
import SubscriptionToaster from './components/SubscriptionToaster';
import GeneratePassword from './components/GeneratePassword';
import TicketDetails from './components/TicketDetails';
import CommonModal from './components/CommonModal';
import { getTokens,messaging } from './firebase';
import SubscriptionModal from './components/SubscriptionModel';
// import UAParser from 'ua-parser-js';


import logo from '../src/img/home_log.png';
import PluginList from './components/PluginList';
import Wishlist from './components/Wishlist';
import Queue from './components/Queue';
import { toast } from 'react-toastify';
import { onMessage } from 'firebase/messaging';
import { setNotificationAlert } from './features/notifyNumbers';
import NewStore from './components/NewStore';

const storePlugins = localStorage.getItem('storequeue') !== null ? JSON.parse(localStorage.getItem('storequeue')!) : [];

function App() {

  const { data: initialData = { data: [{ session_data: '' }] } } = useUserSessionQuery({ data: {} });
  // const {data: allPlugins} = useAllPluginQuery({flag:"all"});
  const { data:userdetails } = useAppSelector((state:any) => state.userDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState<any>([])
  const [subscription_status, setsubscription_status] = useState<any>('')
  const [showContainer, setShowContainer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const [isTokenFound, setTokenFound] = useState<boolean>(false);
  const [token, setToken] = useState<any>('')
  const [updateOtpPreference,] = useUpdateOtpPreferenceMutation()
  const [show, setShow] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState<any>({})
  const [hideEmoji, setHideEmoji] = useState(true)
  // getTokens(setTokenFound,setToken);
  // console.log("XXXX",token)
  // const data = {
  //   flag:"fcm_token",
  //   fcm_token:token
  // }
  // console.log(data)
  console.log("userdetails",userdetails)
  const pathtoAvoid = ['/auth','/authOtp','/forgot-password','/reset-password','/set-password']
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (pathtoAvoid.includes(currentPath)) {
      if (typeof userdetails !== 'undefined' && Object.prototype.hasOwnProperty.call(userdetails, 'email') || localStorage.getItem('token') !== null) {
        navigate('/');
      }
    }
  }, [window.location.pathname]);

useEffect(()=>{
  // console.log("Navigator",navigator);
  // const { platform, userAgent } = navigator;
  // const navigatorInfo = {
  //   platform,
  //   userAgent
  // };
  // setDeviceDetails(navigatorInfo)
  const userAgent = navigator.userAgent;
  let browserName;
  let browserVersion;

  if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Mozilla Firefox";
      browserVersion = userAgent.split("Firefox/")[1];
  } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Google Chrome";
      browserVersion = userAgent.split("Chrome/")[1].split(" ")[0];
  } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Apple Safari";
      browserVersion = userAgent.split("Version/")[1].split(" ")[0];
  } else if (userAgent.indexOf("MSIE") > -1 || !!document.DOCUMENT_NODE) {
      browserName = "Internet Explorer";
      browserVersion = userAgent.split("MSIE ")[1].split(";")[0];
  } else if (userAgent.indexOf("Edge") > -1) {
      browserName = "Microsoft Edge";
      browserVersion = userAgent.split("Edge/")[1];
  } else {
      browserName = "Unknown Browser";
      browserVersion = "N/A";
  }
  const navigatorInfo = {
      browserName,
      browserVersion
    };
    setDeviceDetails(navigatorInfo)
},[])
console.log("Device_Details",deviceDetails)

  useEffect(() => {
    const fetchToken = async () => {
      await getTokens(setTokenFound, setToken);
    };
    fetchToken();
  }, []);

  const fcm_data = {
    browser:deviceDetails.browserName,
    fcm_token: token,
    flag:'fcm_token'
  }

  console.log('fcm_data', fcm_data);
    if(userDetails){
      const existingDataString = localStorage.getItem('fcm_data');
      let existingData = [];
      try {
        existingData = existingDataString ? JSON.parse(existingDataString) : [];

        if (!Array.isArray(existingData)) {
          existingData = [];
        }
      } catch (error) {
        existingData = [];
      }

      const index = existingData.findIndex((item:any) => item.browser === deviceDetails.browserName);

      if (index !== -1) {
        existingData[index] = fcm_data;
      } else {
        existingData.push(fcm_data);
      }
    if(token){
      const updatedDataString = JSON.stringify(existingData);
      localStorage.setItem('fcm_data', updatedDataString);
      console.log('Updated fcm_data array', existingData);
    }
  }


  useEffect(() => {
    if (token) {
      const data = {
        flag: "fcm_token",
        fcm_token: localStorage.getItem('fcm_data'),
      };

      updateOtpPreference(data)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error("Error updating OTP preference:", error);
        });
    }
  }, [token]);


  // onMessageListener().then((payload:any) => {
  //   console.log("payload",payload)
  //   const decodedMessage = payload.data ? JSON.parse(payload.data.message) : {}
  //   setShow(true);
  //   toast.info(decodedMessage)
  //   // new Notification("Fusepress",{
  //   //   body: "Project has been deployed"
  //   // })
  //   // setNotification({title: payload.notification.title, body: payload.notification.body})
  //   // console.log(payload);
  // }).catch(err => console.log('failed: ', err));

  onMessage(messaging, (payload:any) => {
    console.log("payload",payload)
    dispatch(setNotificationAlert(payload))
    // resolve(payload);
    new Notification("Fusepress",{
          body: payload.data.message
        })
  });



  useEffect(() => {
    // localStorage.setItem("language","en")
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      console.log(i18n)
      i18n.changeLanguage(savedLanguage);
    }
  },[i18n]);


  useEffect(()=>{
    dispatch(fetchUserDetails())
  },[dispatch])


  useEffect(() => {
    if (userdetails && Object.keys(userdetails).length > 0) {
      setUserDetails(userdetails);
    }
  }, [userdetails]);

  useEffect(() => {
    if (userDetails && userDetails.subscription_status !== undefined) {
      // console.log(userDetails);
      setsubscription_status(userDetails.subscription_status);

    }
  }, [userDetails]);
  useEffect(() => {
    console.log("window.location.pathname",window.location.pathname)
    const pathtoAvoid = ['/auth','/authOtp','/forgot-password','/reset-password','/set-password','/not-found']
    console.log("hjdfhdjsfh",pathtoAvoid.includes(window.location.pathname))
    if(pathtoAvoid.includes(window.location.pathname)){
      setHideEmoji(false)
      // setShowContainer(false);
    } else{
      setHideEmoji(true)
    }
  },[window.location.pathname])
  useEffect(() => {
    const handleScroll = () => {
      setShowContainer(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleContainer = () => {
    setShowContainer(!showContainer);
  };
  const closeContainer = ()=>{
    setShowContainer(false)
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (Object.prototype.hasOwnProperty.call(initialData, 'error')) {
        if (initialData.error === true && initialData.message === 'Unauthorized.') {
          localStorage.removeItem('token');
          navigate('/auth');
        } else {
          console.log('do nothing');
        }
      } else {
        dispatch(
          setUserPlugins(initialData?.data[0]?.session_data ? JSON.parse(initialData?.data[0]?.session_data) : [])
        );
      }
    }
  }, [initialData, dispatch]);
  // useEffect(() => {
  //   if (allPlugins && allPlugins.items) {
  //     dispatch(setAllPlugins(allPlugins.items))
  //   }
  // }, [allPlugins, dispatch]);



  const removeOldItems = () => {
    const now = new Date().getTime();
    const timestamp = localStorage.getItem('timestamp');

    if (timestamp && now - parseInt(timestamp, 10) > 24 * 60 * 60 * 1000) {
      console.log('hello');
      // localStorage.removeItem("timestamp");
      localStorage.setItem('timestamp', `${now}`);
    }
  };
  const redirecttoQueue = () => {
    navigate('/store/queue')
  }
  console.log(subscription_status)

  useEffect(() => {
    localStorage.setItem('timestamp', `${new Date().getTime()}`);
    // removeOldItems()
    // const interval = setInterval(() => {
    //   removeOldItems();
    // }, 60 * 1000);
    // return () => clearInterval(interval);
  }, []);
  let auth = { token: localStorage.getItem('token') !== null };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const excludeModalRoutes = ['/reset-password', '/set-password', '/forgot-password','/auth','/authOtp'];

  useEffect(() => {
    if (auth.token && !excludeModalRoutes.includes(location.pathname)  && subscription_status !=='active') {
      // setIsModalOpen(true);
      // document.body.classList.add('no-scroll');
      setIsToastOpen(true)
    } else {
      // setIsModalOpen(false);
      setIsToastOpen(false)
      // document.body.classList.remove('no-scroll');
    }
  }, [auth.token, location.pathname, excludeModalRoutes, subscription_status]);
  console.log(location.pathname)
  const handleSubscribe = () => {
    console.log("hello")
    window.open("https://fusepress.co/checkouts/checkout/", "_blank");
  };

  if(Object.keys(userDetails).length === 0 &&location.pathname==="/"){
    return <Loader/>
  }
  // ${isModalOpen ? 'blur'   : ''}
  return (
    <>
      {
        <>
<div className={`wrapper`}>

          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path='/' element={<Home />} />
              <Route path='/' element={<CommonLayout />}>

              <Route path='/plugins' element={<PluginList />} />
                <Route path='/store' element={<StoreList />} />
                <Route path='/user-profile' element={<UserProfile/>}/>
                <Route path='/queue' element={<StoreQueue />} />
                <Route path='/projects' element={<ProjectsList />} />
                <Route path='/store/:slug' element={<PluginDetails />} />
                <Route path='/projects/:projectid' element={<ProjectDetails />} />
                <Route path='/projects/add' element={<AddProject />} />
                <Route path='/projects/import' element={<NewProject />} />
                <Route path='/projects/:projectid/edit' element={<EditProject />} />
                <Route path='/deploy-pipeline' element={<ReviewQueue />} />
                <Route path='/deploy/:typeId' element={<ComposerView />} />
                <Route path='/billing' element={<Billing />} />
                <Route path='/support' element={<Suports />} />
                <Route path='/ticket/:id' element={<TicketDetails />} />
                <Route path='/user-private-packages' element={<UserPrivatePackages />} />
                <Route path='/user-packages' element={<UserPackages />} />
                <Route path='/new-store' element={<NewStore/>} />
                {/* <Route path='/queue' element={<Queue/>}/> */}
                <Route path='/in-package-review' element={<ReviewUserPackage />} />
                <Route path='/in-package-rejected' element={<RejectedUserPackage />} />
                <Route path='/add-user-package' element={<UserAddPackage />} />
                <Route path='/documentation' element={<Documentation />} />
                <Route path='/wishlist' element={<Wishlist/>} />
                <Route path='/generate-password' element={<GeneratePassword/>}/>
                <Route path='*' element={<Navigate replace={true} to='/not-found' />} />
                <Route path='/not-found' element={<NotFound />} />
              </Route>
            </Route>
            <Route element={<Auth />} path='/auth' />
            <Route element={<AuthOtp />} path='/authOtp' />
            <Route element={<ForgotPassword />} path='/forgot-password' />
            <Route element={<ResetPassword />} path='/reset-password' />
            <Route element={<SetPassword />} path='/set-password' />
          </Routes>
          <Toast />
        </div>
      {/* {hideEmoji&& !showContainer&&<div className={`floating-icon ${showContainer ? 'hidden' : ''}`} onClick={() => toggleContainer()}>
        {location.pathname !== '/store' && storePlugins.length > 0 && (
        <div className='container '>
          <div className='sticky' style={{zIndex:'9999999'}}>
            <FontAwesomeIcon icon={faSmile} color='white' size='xl' />
            <FontAwesomeIcon icon={faSquareCaretLeft} size='2xl' style={{color:'#f08921'}}/>
            <div className='badge'>{storePlugins.length}</div>
          </div>
        </div>
        )}
      </div>} */}
      {/* {isToastOpen && !isModalOpen && <SubscriptionToaster setIsToastOpen={setIsToastOpen} isToastOpen={isToastOpen} setIsModalOpen={setIsModalOpen} subscription_status={subscription_status} />} */}
      {/* {
  isModalOpen && <SubscriptionModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}  subscription_status={subscription_status}/>
} */}
{/* <SubscriptionModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} subscription_status={subscription_status} children={undefined}  /> */}

{/* <div className="logo" style={{ color: "white" }}>
                                <h5>{t('subscription-Model-Text')}</h5>
                                <h6>👉🏻 {t('subscription-Model-Sub-text')} {subscription_status} 🪧 </h6>

                            </div>
</SubscriptionModal> */}
{/* <CommonModal  onHide={setIsModalOpen} show={isModalOpen}  subscription_status={subscription_status}>
        <CommonModal.Body>
        <div className="logo" style={{color:"white"}}>
                <img src={logo} alt="" style={{ width: '200px', height: '100px' }} />
                <h5>{t ('subscription-Model-Text')}</h5>
                <h6>👉🏻 {t ('subscription-Model-Sub-text')}{subscription_status} 🪧 </h6>
                <button className='orange_btn' onClick={() => handleSubscribe()}>{t ('subscription-Model-Button')}</button>
              </div>
        </CommonModal.Body>
</CommonModal> */}

      {/* {showContainer && (
        <div className='container'>
          {location.pathname !== '/store' && storePlugins.length > 0 && (
            <div className='sticky-container'  style={{zIndex:'999999'}}>
              <div className='selected-plugin'>
              <FontAwesomeIcon icon={faCircleXmark} color='white' size='xl' onClick={closeContainer} style={{marginRight:'10px'}} />
                <span className='text-white'>{t('store-list.Selected-Packages')}: {storePlugins.length}</span>
                <button className='orange_btn m-1' onClick={() => redirecttoQueue()}>{t('store-list.buttons.Queue')}</button>
              </div>
            </div>
          )}
        </div>
      )} */}
        </>
      }
    </>
  );
}

export default App;
