import React from 'react';
import "../sass/pages/new_store.scss";

const NewStore = () => {
  return (
    <>
      <main className='main'>
        <div className='container-fluid'>
          <div className='store_banner'>
            <div className="store_header">
              <h1 className="store_title text-white">Flex your site’s features with plugins</h1>
              <p className="store_subtitle">Add new functionality and integrations to your site with thousands of plugins.</p>
              <div className="store_search" style={{ display: "flex", maxWidth: "350px", right: "0px", }}>
                <div className="search d-flex">
                  <div className="search_main">
                    <input type="text" placeholder="Search here" />
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" className="svg-inline--fa fa-magnifying-glass " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="#6DB1BC" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className=''>
              <div className="d-flex">
                <button className="orange_btn d-flex align-items-center mx-1" type="button">Discover</button>
                <button className="orange_btn d-flex align-items-center mx-1" type="button">Search Engine Optimization</button>
                <button className="orange_btn d-flex align-items-center mx-1" type="button">Ecommerce & Business</button>
                <button className="orange_btn d-flex align-items-center mx-1" type="button">Booking & Scheduling</button>
                <button className="orange_btn d-flex align-items-center mx-1" type="button">Events Calendar</button>
                <button className="orange_btn d-flex align-items-center mx-1" type="button">Social</button>
                <button className="orange_btn d-flex align-items-center mx-1" type="button">Email</button>
                <div className='last_dropdown d-flex align-items-center mx-1'>
                  <div className="dropdown">
                    <button className="orange_btn d-flex align-items-center dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      More
                    </button>
                    <ul className="dropdown-menu">
                      <li><a className="dropdown-item" href="/">Security</a></li>
                      <li><a className="dropdown-item" href="/">Shipping & Delivery</a></li>
                      <li><a className="dropdown-item" href="/">Analytics</a></li>
                      <li><a className="dropdown-item" href="/">Marketing</a></li>
                      <li><a className="dropdown-item" href="/">Design</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="plugins_list">
            <div className="plugins_header d-flex align-items-end justify-content-between">
              <div className="plugins_titles">
                <h3 className="plugins_header_title text-white">Must-have premium plugins</h3>
                <p className="plugins_header_subtitle">Add the best-loved plugins on WordPress.com</p>
              </div>
              <div className="plugins_actions">
                <button className='cblue_btn'>Browse All</button>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-md-6 col-sm-12">
                <a href="/plugins/wordpress-seo-premium" className="plug_grid">
                  <div className="plugins_info">
                    <div className="plugins_item_header">
                      <div className="plugin_icon">
                        <img className='img-fluid' src="https://woocommerce.com/wp-content/uploads/2022/08/yoast-icon-160x160-1.png" alt="plugin_icon" />
                      </div>
                      <div className="plugins_title_author">
                        <div className="plugins_item_title"><h6>Yoast SEO Premium</h6></div>
                        <div className="plugins_item_author"><p>by <span className="plugins_item_author_name">Team Yoast</span></p></div>
                      </div>
                    </div>
                    <div className="plugins_item_description">
                      <p>All the benefits of Yoast SEO Free as well as exclusive SEO features which unlock even more tools and functionality.</p>
                    </div>
                  </div>
                  <div className="plugins_footer">
                    <div className="plugins_item_pricing"><p>₹711.00 <span className="plugins_item_period">monthly</span></p></div>
                    <div className="plugins_item_additional_info"></div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-3 col-xl-3 col-md-6 col-sm-12">
                <a href="/plugins/wordpress-seo-premium" className="plug_grid">
                  <div className="plugins_info">
                    <div className="plugins_item_header">
                      <div className="plugin_icon">
                        <img className='img-fluid' src="https://woocommerce.com/wp-content/uploads/2023/01/SenseiProLogoIcon-1.png" alt="plugin_icon" />
                      </div>
                      <div className="plugins_title_author">
                        <div className="plugins_item_title"><h6>Sensei Pro</h6></div>
                        <div className="plugins_item_author"><p>by <span className="plugins_item_author_name">Automattic</span></p></div>
                      </div>
                    </div>
                    <div className="plugins_item_description">
                      <p>Sensei Pro is a premium plugin for WordPress that enables users to create, manage, and sell online courses. It provides a comprehensive suite of tools for creating engaging course content, tracking student progress, and assessing their performance. With its intuitive interface, customizable options, and comprehensive features, Sensei Pro is an ideal choice for creators and educators looking to grow their online knowledge business.</p>
                    </div>
                  </div>
                  <div className="plugins_footer">
                    <div className="plugins_item_pricing"><p>₹711.00 <span className="plugins_item_period">monthly</span></p></div>
                    <div className="plugins_item_additional_info">
                      <div className="plugins_item_ratings">
                        <svg className="star-svg" stroke="currentColor" fill="#f08921" stroke-width="0" viewBox="0 0 24 24" width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg>
                        <span className="plugins_item_rating_value"><p>4.6</p></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="plugins_list">
            <div className="plugins_header d-flex align-items-end justify-content-between">
              <div className="plugins_titles">
                <h3 className="plugins_header_title text-white">Supercharging and monetizing your blog</h3>
                <p className="plugins_header_subtitle">Building a money-making blog doesn’t have to be as hard as you might think</p>
              </div>
              <div className="plugins_actions">
                <button className='cblue_btn'>Browse All</button>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-md-6 col-sm-12">
                <a href="/plugins/wordpress-seo-premium" className="plug_grid">
                  <div className="plugins_info">
                    <div className="plugins_item_header">
                      <div className="plugin_icon">
                        <img className='img-fluid' src="https://woocommerce.com/wp-content/uploads/2022/08/yoast-icon-160x160-1.png" alt="plugin_icon" />
                      </div>
                      <div className="plugins_title_author">
                        <div className="plugins_item_title"><h6>Yoast SEO Premium</h6></div>
                        <div className="plugins_item_author"></div>
                      </div>
                    </div>
                    <div className="plugins_item_description">
                      <p className='mb-0'>Optimize your site for search engines</p>
                    </div>
                  </div>
                  <div className="plugins_footer">
                    <div className="plugins_item_pricing"></div>
                    <div className="plugins_item_additional_info"></div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-3 col-xl-3 col-md-6 col-sm-12">
                <a href="/plugins/wordpress-seo-premium" className="plug_grid">
                  <div className="plugins_info">
                    <div className="plugins_item_header">
                      <div className="plugin_icon">
                        <img className='img-fluid' src="https://ps.w.org/give/assets/icon-256x256.jpg?rev=2659032" alt="plugin_icon" />
                      </div>
                      <div className="plugins_title_author">
                        <div className="plugins_item_title"><h6>GiveWP</h6></div>
                        <div className="plugins_item_author"></div>
                      </div>
                    </div>
                    <div className="plugins_item_description">
                      <p className='mb-0'>Create donation pages and collect more</p>
                    </div>
                  </div>
                  <div className="plugins_footer">
                    <div className="plugins_item_pricing"></div>
                    <div className="plugins_item_additional_info"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className='mid_banner'>
            <div className='mid_banner_inner'>
              <div className='mid_banner_blocks row'>
                <div className='mid_banner_block col-md-6 col-12'>
                  <div className='mid_banner_img_views'>
                    <div className='language_logo'>
                      <img src='https://wordpress.com/calypso/images/wp-logo-0496ecb3fdfc3ade5284.svg' alt="wordpress" />
                    </div>
                    <img className='mid_banner_img' src='https://wordpress.com/calypso/images/plugins-video-62a570cbe6f13ed53701.jpg' alt="mid_banner" />
                    <div className="mid_banner_views">Views <span className="mid_banner_views_amount">1,250</span></div>
                  </div>
                </div>
                <div className='mid_banner_block col-md-6 col-12'>
                  <div className='mid_banner_info'>
                    <h2>Build your site. Sell your stuff. Start your blog. This and much more with unlimited plugins!</h2>
                    <button className='orange_btn'>Get Started</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="plugins_list">
            <div className="plugins_header d-flex align-items-end justify-content-between">
              <div className="plugins_titles">
                <h3 className="plugins_header_title text-white">Get started with plugins</h3>
                <p className="plugins_header_subtitle">Our favorite how-to guides to get you started with plugins</p>
              </div>
              <div className="plugins_actions">
                <button className='cblue_btn'>Browse All</button>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-md-6 col-sm-12">
                <a href="/plugins/wordpress-seo-premium" className="plug_grid">
                  <div className="plugins_info">
                    <div className="plugins_item_description">
                      <p>What Are WordPress Plugins? Everything You Need to Know as a Beginner</p>
                    </div>
                  </div>
                  <div className="plugins_footer">
                    <div className="plugins_item_additional_info">
                      <button className='cblue_btn'>Learn more</button>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xxl-3 col-xl-3 col-md-6 col-sm-12">
                <a href="/plugins/wordpress-seo-premium" className="plug_grid">
                  <div className="plugins_info">
                    <div className="plugins_item_description">
                      <p>How to Install Plugins on Your WordPress.com site: The Complete Beginner's Guide</p>
                    </div>
                  </div>
                  <div className="plugins_footer">
                    <div className="plugins_item_additional_info">
                      <button className='cblue_btn'>Learn more</button>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className='marketplace_footer'>
            <div className='marketplace_footer_inner'>
              <div className='marketplace_title'>
                <h2>You pick the plugin. We’ll take care of the rest.</h2>
                <button className='orange_btn'>Get Started</button>
              </div>
              <div className='marketplace_blocks row'>
                <div className='marketplace_block col-md-4 col-12'>
                  <div className='marketplace_info'>
                    <h6 className='text-white'>Fully Managed</h6>
                    <p>Premium plugins are fully managed by the team at WordPress.com. No security patches. No update nags. It just works.</p>
                  </div>
                </div>
                <div className='marketplace_block col-md-4 col-12'>
                  <div className='marketplace_info'>
                    <h6 className='text-white'>Thousands of plugins</h6>
                    <p>From WordPress.com premium plugins to thousands more community-authored plugins, we’ve got you covered.</p>
                  </div>
                </div>
                <div className='marketplace_block col-md-4 col-12'>
                  <div className='marketplace_info'>
                    <h6 className='text-white'>Flexible pricing</h6>
                    <p>Pay yearly and save. Or keep it flexible with monthly premium plugin pricing. It’s entirely up to you.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default NewStore